#notfound {
    display: flex;
    justify-content: center;
    @include page-height;
}

.notfound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    hr {width: 100%;}
}

