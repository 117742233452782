.contactsuccess.modal {
    z-index: 51;

    .contactsuccess-container {
        min-height: 0;
        // max-width: 550px;
        max-width: 450px;
        // width: 100%;
    
        // text-align: center;
        
        header { margin: 0; }    
    
        h1 {width: 100%;}
    
        hr {margin: 5px 0;}    
    }
    
    .contactsuccess-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0;
    
        // span {color: var(--font-color-2)}

        // p:not(:first-of-type) {
        //     border-top: 1px solid var(--border-color-2);
        //     padding-top: 10px;
        // }
    }
}

