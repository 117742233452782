.reply {
    display: flex;
    flex-direction: column;
    gap: 15px;
    // padding: 10px;

    margin-top: 20px;
    background: var(--bubble_input-background_color-1);
}

.reply-user-date::before {
    background-color: var(--bubble_input-background_color-1);
}

.reply-stats {
    display: flex;
    margin-top: 10px;

    &-likes {
        display: flex;
        gap: 3px;
    }
}