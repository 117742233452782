.verifyemail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    gap: 10px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 350px;
    padding: 10px;
    border: 1px solid var(--border-color-1);
    border-radius: 10px;

    background-color: var(--ui-background_color-1);
    box-shadow: $ui-box_shadow-1;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }
}

