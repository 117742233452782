.header {
    position: relative;

    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;

    z-index: 100;

    height: var(--header-height); // $header-height;

    background: var(--ui-background_color-1);
    border-bottom: 2px solid var(--border-color-1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5px 30px;

    button, .header-link {
        display: flex;
        align-items: center;

        height: 44px;
        min-width: 44px;
        padding: 10px 15px;

        text-align: center;
        font-weight: 700;

        width: max-content;

        border: 1px solid var(--border-color-1);
        border-radius: 8px;
    }

    .searchbar {
        max-width: 500px; //1000px;
        width: 100%;
    }

    .dropdownnav {

        &-content {
            width: 250px; // 195px;
            top: var(--header-height); // $header-height;
            right: 30px;
        }

        .show-in-tablet-view { display: none; }
    }
}

.header-logo, .header-logo-link {
    height: 100%;
    max-height: calc(var(--header-height) - 20px);
    width: auto;
}

.header-button-container {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.header-theme-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
}

// Laptop
@media screen and (max-width: 1279px) {
    .header-container {
        padding: 5px 10px;
    
        .dropdownnav-content { right: 10px; }
    }
}


@media screen and (min-width: 1024px) {
    .header-container {
        display: grid;
        grid-template-columns: 1fr 500px 1fr;
        
    }
}

// Tablet
@media screen and (max-width: 768px) {
    .header-container {

        .hide-in-tablet-view { display: none !important; }
        .show-in-tablet-view { display: block !important; }

        .dropdownnav {
            // position: static;
    
            &-content {
                width: 100%;
                left: 0;
                right: 0;
            }
        }
    }

}

// Mobile
// @media screen and (max-width: 599px) {}

