.searchbar {
    position: relative;

    &:focus, &:focus-within {
        .searchbar-suggestions {
            &, .searchbar-suggestions-arrow {
                display: block;
            }
        }
    }
}

.searchbar, .searchbar-suggestions {
    &:focus, &:focus-within {
        display: block;
    }
}

.searchbar-input-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    input { 
        margin: 0;
        padding-right: 48px;
    }

    a {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        background-color: var(--ui-background_color-1);
        border-radius: 0 8px 8px 0;
        border-left: 1px solid var(--border-color-1);

        transition: background-color 0.3s ease-in-out;

        &:hover { 
            i {color: var(--font_hover-color-1);}
            background-color: var(--button_hover-background_color-1);
        }
        &:active {
            i {color: var(--font_active-color-1);}
            background-color: var(--button_active-background_color-1);
        }

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            color: var(--font-color-1);

            transition: color 0.3s ease-in-out;
        }
    }
}

.searchbar-suggestions{
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 56px;
    z-index: 1;
    
    background-color: var(--bubble-background_color-1);
    border: 1px solid var(--border-color-1);
    border-radius: $ui_interior-border_radius-1;
    box-shadow: $ui-box_shadow-1;
    
    &-arrow {
        position: absolute;
        top: -13px;
        left: 30px;
        border-bottom: 13px solid var(--ui-background_color-1);
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        
        &::before {
            z-index: -1;
            content: "";
            position: absolute;
            top: -1px;
            left: -10px;

            border-bottom: 14px solid var(--border-color-1);
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
        }
    }

    &-header {
        text-align: end;
        font-size: 10px;
        padding: 3px 5px;
        background-color: var(--ui-background_color-1);
        border-radius: $ui_interior-border_radius-1;
    }

    &-empty {
        display: block;
        text-align: center;
        padding: 30px;
    }

    .searchbar-suggestion {
        display: grid;
        // grid-template-columns: 70px 1fr;
        gap: 10px;

        // min-height: 110px;
        // max-height: 130px;
        padding: 5px 10px;
        


        border-top: 1px solid var(--border-color-1);

        // border-bottom: 1px solid $border-color-1;

        transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
        
        &:hover {
            color: var(--button-text-hover-color-1);
            background-color: var(--button_hover-background_color-1);
            cursor: pointer;
        }
        &:active {
            color: var(--button-text-active-color-1);
            background-color: var(--button_active-background_color-1);
        }
    }   
}

.searchbar.show-suggestions .searchbar-suggestions {
    display: block; // Show suggestions when .show-suggestions is added to .searchbar
}

// @mixin show-search-when-focused {
//     .searchbar-input-container {

//         &:hover:not(:focus), &:hover:not(:focus-within){
//             i {color: $secondary-color;}
//         }

//         i a {
//             display: none;
//             position: absolute;
//             display: none;
//             top: 0;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             z-index: 1;
//         }

//         &:focus-within, &:focus {
            
//             input {
//                 width: 100%;
//                 z-index: 0;
//                 opacity: 1;
//                 background-color: $accent-light-color;
//                 cursor: text;
//             }

//             i {
//                 z-index: 2;
//                 a {display: block;}
//             }
//         }
        
//         input {
//             width: 45px;
//             z-index: 1;
//             opacity: 0;
//             color: $secondary-font-color;
//             transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
//             background-color: transparent;
//             cursor: pointer;
//         }

//         i {
//             z-index: 0;
//             transition: z-index 0s 0.5s;
//         }
//     }
// }

// .searchbar-results-container {
//     position: absolute;
//     right: 0;
//     top: 58px;
//     width: 380px;
//     max-height: 600px;
//     z-index: 102;
    
//     background-color: $font_color-1;

//     &, &-arrow{
//         display: none;
//         // opacity: 0;
//         transition: opacity 0.3s ease-in-out;
//     }
    
//     .searchbar-results-arrow {
//         position: absolute;
//         top: -13px;
//         right: 30px;
//         border-bottom: 13px solid $font_color-1;
//         border-right: 9px solid transparent;
//         border-left: 9px solid transparent;
//     }

//     .searchbar-results-header {
//         text-align: end;
//         font-size: 10px;
//         padding: 3px 5px;
//         background-color: $font_color-1;
//     }

//     .searchbar-results-empty {
//         display: block;
//         text-align: center;
//         padding: 30px;
//     }

//     .searchbar-product-result {
//         display: grid;
//         grid-template-columns: 70px 1fr;
//         gap: 10px;

//         min-height: 110px;
//         max-height: 130px;
//         padding: 5px 10px;
        
//         border-top: 1px solid $border-color-1;
//         border-bottom: 1px solid $border-color-1;

//         &:hover {
//             background-color: #e6e2e2b2;
//         }

//         &__image-container {
//             width: 70px;
//             height: 73px;

//             img {
//                 height: auto;
//                 width: 100%;
//                 margin: auto;
//             }
//         }

//         &__name {
//             color: $font_color-1;
//             font-size: 16px;
//         }

//         &__description {
//             -webkit-line-clamp: 2;
//             overflow: hidden;
//             max-height: 2.8em;

//             &, p, h1, h2, span {
//                 margin: 0;
//                 font-size: 16px !important;
//                 color: #666;
//             }
//         }

//         &__price {
//             font-size: 18px;
//             color: $font_color-1;

//             del {
//                 font-size: 14px;
//                 color: #666;
//             }
//         }
//     }

//     .searchbar-results-link {
//         display: block;
//         padding: 5px 10px;
//         text-align: center;
//         color: $font_color-1;

//         &:hover {
//             background-color: #e6e2e2b2;
//         }
//     }
// }