// .viewproblemsolution {}

.viewproblemsolution-container {
    display: flex;
    justify-content: center;

    // width: 100%;
    // max-width: 1002.19px; // 550px;
    max-width: 1247.75px;
    width: calc(100vw);
    margin: 3px;
    // width: calc(100vw - 26px);
    z-index: 50;

    // @media (max-width:599px) {
        margin-top: 20px;
    // }

    &.no-results {
        flex-direction: column;
        padding: 10px;
        border-radius: $ui-border_radius-1;
        border: 1px solid var(--border-color-1);
        background-color: var(--ui-background_color-1);

        max-width: 500px;
        width: 100%;

        & > h1 {
            padding: 50px 0px;
            text-align: center;
            margin: 0;
            background-color: var(--ui_interior-background_color-1);
            border-radius: $ui_interior-border_radius-1;
            border: 1px solid var(--border-color-1);
        }
    }

    .problemsolution {
        margin: auto 3px;
        width: 100% !important;
        &-details {transition: none !important;}
    }
}

.viewproblemsolution-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.viewproblemsolution-form-title-counter {
    display: flex;
    text-align: right;
    margin-top: 5px;
}

.viewproblemsolution-error-text {
    color: var(--font_danger-color-1);
    margin-right: auto;
    text-align: left;
}

