.likebutton-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .likebutton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }    
}