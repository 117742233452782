.loginsignup.modal {
    z-index: 51;

    .loginsignup-container {
        min-height: 0;
        max-width: 550px;
        // width: 100%;
        
        &.email-verification {
            header {
                display: grid;
                grid-template-columns: 1fr 42px;
                gap: 5px;

                margin: 0;
                h1 {
                    // width: 100%;
                    margin-left: 47px;
                }
            }
        
            text-align: center;
        
            hr {margin: 5px 0 5px 0;}
        
            max-width: 450px;
            width: 100%;
        }
    }
}