.comment, .reply {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px 10px 10px 10px;

    border: 1px solid var(--border-color-1);
    border-radius: $ui-border_radius-1;

    background-color: var(--bubble-background_color-1);

    &-text-container button {

        border: none;
        color: var(--font-color-2);
        margin-top: 5px;

        &, &:hover, &:active { background-color: transparent; }
    }

    &-text {
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 80px;

        &.read-more {
            max-height: none;
        }

        // &:not(.read-more) {
        //     ::after {
        //         content: '';
        //         position: absolute;
        //         bottom: 0;
        //         left: 0;
        //         width: 100%;
        //         // height: 70px; /* Height of the fading effect */
        //         background: linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 1)); /* Adjust the color to match your container's background */
        //     }
        // }
    }

    .dropdownmenu {
        // position: absolute;
        // right: 0;
        // top: 0;
        button {
            border: none;
            padding: 0 10px;
            height: 21px;
        }
    }
}

.comment-user-date, .reply-user-date {
    position: absolute;
    top: -10px;
    // left: 10px;
    border-radius: var(--ui_interior-border_radius-1);

    display: flex;
    gap: 5px;

    padding: 2px 5px;
    // margin-left: -5px;

    p, nav { z-index: 1;}
    
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 15px;
        border-radius: 5px 5px 0 0;
        border: 1px solid var(--border-color-1);
        border-bottom: none;

        background-color: var(--bubble-background_color-1);
    }
}

// .comment-user { font-weight: 600; }
// .comment-date { font-style: italic; }

.comment-stats {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin-top: 10px;
}

.comment-buttons {
    display: flex;
    gap: 5px;

    // i {margin-top: 6px;}

    &.absolute {
        position: absolute;
        bottom: 0px;
    }
}

.comment-likes {
    display: flex;
    gap: 3px;
}

.comment-reply {
    margin-left: auto;
}

.comment-replies-button {
    display: flex;
    align-items: center;
    gap: 3px;
    &.flip-carat i { transform: rotate(180deg); }
}

.comment-form, .reply-form {
    display: flex;
    flex-direction: column;
    justify-content: end;

    .ql-editor, textarea {
        // border-top: none;
        // border-left: none;
        // border-right: none;
        border-radius: 5px; // 0;

        // padding: 10px 5px;
        padding: 10px;
        margin-bottom: 15px;

        width: 100% !important;
        min-height: 44px; // 64px;

        // min-height: 44px;
        // max-height: 256px;

        // font-size: 16px;
    }

    &-indicator {
        display: grid;
        grid-template-columns: 1fr 70px;
        gap: 5px;

        margin-top: -10px;
        margin-bottom: 10px;

        .character-count {
            text-align: right;
        }
    }

    // .error-message {}

    &-buttons {
        display: flex;
        gap: 5px;
        justify-content: end;
    }
}

.reply-form {
    margin-top: 10px;

    textarea { background: #3333; }
}

.comment-reply-list {
    position: relative;
    overflow: hidden;
    min-height: 0;
    max-height: 0;
    margin-top: -10px;
    
    // transition: min-height 0.3s ease-in-out;
    
    &.open {
        margin-top: -5px;
        min-height: 115px;
        max-height: 100%;
        hr { margin: 10px 0; }
    }

    .loading-icon { top: calc(50% + 2px) }
}

