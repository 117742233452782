.loginsignupform {

    // padding: 10px;
    margin-top: 10px;

    // background-color: $ui_interior-background_color-1;
    // border: 1px solid $border_color-1;
    // border-radius: $ui_interior-border_radius-1;
    // box-shadow: $ui_interior-box_shadow-1;

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        margin-top: 15px;

        label { margin-bottom: -20px; }
        input { margin: 0; }

        .password-input-container {
            overflow: hidden;
            position: relative;
            display: flex;

            border-radius: $ui-border_radius-1;
            border: 1px solid var(--border-color-1);
            transition: border-color 0.3s;

            &:focus-within {
                border-color: var(--border_focus-color-1);
            }

            input { 
                padding-right: 48px;
                border-radius: 0; 

                &, &:focus { border: none; }
            }

            button {
                position: absolute;

                right: 0px;
                top: 0px;
                bottom: 0px;

                width: 45px;
                height: 100% !important; // 44px;

                border: none;
                border-left: 1px solid var(--border-color-1);
                border-radius: 0;

                &:not(:hover):not(:active) { background-color: var(--ui-background_color-1); }

                // &:hover {
                //     background-color: $button_hover-background_color-1;
                // }
            }
        }

        .date-of-birth-input {
            position: relative;
            
            &::-webkit-calendar-picker-indicator { 
                opacity: 0; 
                // background-color: red;
                position: absolute;
                right: 0px;
                top: 0px;
                bottom: 0px;

                width: 45px;
                height: 100% !important; // 44px;

                // color: transparent !important;
                // background-color: transparent; // $primary-button-background-color;
                
                cursor: pointer;
            }
            
            &::after {
                content: '\f073'; // Calendar icon from Font Awesome
                font-family: 'Font Awesome 5 Free';
                pointer-events: none; // Allows clicks to pass through to the input

                display: flex;
                align-items: center;
                justify-content: center;

                position: absolute;

                right: 0px;
                top: 0px;
                bottom: 0px;

                width: 45px;
                height: 100% !important; // 44px;

                // border: none;
                // border-left: 1px solid var(--border-color-1);
                // border-radius: 0;

                // &:not(:hover):not(:active) { background-color: var(--ui-background_color-1); }

                // transition: background-color 0.3s, color 0.3s;

    
    
                color: var(--button-text-color-1);
                background-color: var(--button-background_color-1);
            }

            // &::after, &::before {
            //     transition: background-color 0.3s, color 0.3s;
            //     &:hover {
            //         color: var(--button-text-hover-color-1);
            //         background-color: var(--button_hover-background_color-1);
            //     }
                
            //     &:active {
            //         color: var(--button-text-active-color-1);
            //         background-color: var(--button_active-background_color-1);
            //     }
            // }
        }

        button {
            text-align: center;
            width: 100%;
            height: 40px;
            font-weight: 700;
        }
    }

}

.loginsignup-state-swapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
}

.loginsignup-agree {
    display: flex;
    align-items: baseline;
    gap: 5px;

    input {
        height: auto;
        width: auto;
    }
}

.loginsignup-state-swapper, .loginsignup-agree {

    span, a {
        cursor: pointer;

        &:not(:hover):not(:active) { color: var(--font_toggle-color-1); }
        &:active { color: var(--font_active-color-1); }
        &:hover { color: var(--font_hover-color-1); }
    }
}

.loginsignupform-verification {
    text-align: center;
    hr {margin: 5px 0px;}
}

.loginsignup-error {
    input { border: 1px solid var(--font_danger-color-1); }

    &-text { 
        margin: -15px 0 0px 5px;
        color: var(--font_danger-color-1); 
    }
}

