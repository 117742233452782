@import "./Config";
@import "./Theme";

// COMPONENTS ----------------------------------------------
@import "../components/header/header";
@import "../components/dropdownNav/dropdownNav";
@import "../components/searchbar/searchbar";

@import "../components/loginSignupForm/loginSignupForm";

@import "../components/dropdownMenu/dropdownMenu";
@import "../components/problemSolution/problemSolution";
@import "../components/richTextWithToolbar/richTextWithToolbar";

@import "../components/commentsBox/commentsBox";
@import "../components/comment/comment";
@import "../components/reply/Reply";

@import "../components/likeButton/LikeButton";
@import "../components/adSpace/AdSpace";
@import "../components/spinner/spinner";
@import "../components/scrollButton/scrollButton";

@import "../components/footer/footer";

// MODALS ----------------------------------------------
@import "../modals/loginSignup/loginSignup";
@import "../modals/moderationNotice/moderationNotice";
@import "../modals/editComment/editComment";
@import "../modals/editUsername/editUsername";
@import "../modals/reportForm/reportForm";
@import "../modals/contactSuccess/contactSuccess";

@import "../modals/createProblemSolution/createProblemSolution";
@import "../modals/viewProblemSolution/viewProblemSolution";

@import "../modals/viewProfile/viewProfile";
@import "../modals/viewProfile/profileGeneralTab/ProfileGeneralTab";
@import "../modals/viewProfile/profilePostsTab/ProfilePostsTab";
@import "../modals/viewProfile/profileCommentsTab/ProfileCommentsTab";

// PAGES ----------------------------------------------
@import "../pages/home/home";
@import "../pages/loginSignup/loginSignup";
@import "../pages/account/account";
@import "../pages/account/accountGeneralTab/accountGeneralTab";
@import "../pages/account/accountPostsTab/accountPostsTab";
@import "../pages/resetPassword/resetPassword";
@import "../pages/verifyEmail/verifyEmail";
@import "../pages/notFound/notFound";
@import "../pages/legal/legal";
@import "../pages/about/about";
@import "../pages/contact/contact";
@import "../pages/settings/settings";
@import "../pages/legal/privacyPolicy/privacyPolicy";


// .main-content {}
// html {
//     overflow: hidden;
//     position: relative;
//     height: 110vh;
// }

body { 
    // overflow: hidden 
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    background: var(--page-background_color-1), #1b1b1b;
}

.App {
    // overflow: hidden;
    height: 100dvh;
    // display: grid;
    // grid-template-rows: $header-height 1fr $footer-height;

    &.modal-open { 
        overflow: hidden; 
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @media screen and (max-width: 768px) {

        // Hide scrollbar for Webkit browsers
        &::-webkit-scrollbar { display: none; }        
        scrollbar-width: none; // Hide scrollbar for Firefox        
        -ms-overflow-style: none; // For IE and Edge
    }
}

.page {    
    position: relative;
    // height: $page-height; //Header + Footer
    @include page-height;

    overflow-x: hidden;
    overflow-y: scroll;
    
    padding: 30px 26px 30px 30px;

        
    @media screen and (max-width: 1280px) {
        padding: 30px 6px 30px 10px;
    }    
    @media screen and (max-width: 768px) {
        padding: 30px 10px 60px 10px;

        // Hide scrollbar for Webkit browsers
        &::-webkit-scrollbar {
            display: none;
        }
        // Hide scrollbar for Firefox
        scrollbar-width: none;
        // For IE and Edge
        -ms-overflow-style: none;
    }
    @media screen and (max-width: 600px) {
        padding: 30px 3px 60px 3px;
    }
}

.modal {    
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: var(--header-height); // $header-height;
    left: 0;
    right: 0;
    bottom: var(--footer-height); // $footer-height;
    z-index: 50; // 1000;

    &-overlay { // For having a page background overlay
        z-index: 50;
        position: fixed;
        top: 0; // $header-height;
        left: 0;
        bottom: 0;
        right: 0;

        animation: fadeIn 0.3s ease-out forwards; // Animation to fade in

        background: var(--overlay-background_color-1);
        cursor: pointer;
    }
    
    &-container { // For centering the inner modal container
        overflow-x: hidden;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        
        // overflow: hidden;
        position: relative;
        z-index: 51;
        // top: 50%; // calc(50% + ($header-height / 2 - $footer-height / 2));
        // left: 50%;
        // transform: translate(-50%, -50%);
    
        padding: 10px;
        width: calc(100% - 20px);    
        min-height: 300px;
        max-height: 100%; // calc(100% - 20px);// calc(100vh - ($header-height + $footer-height) - 10px);
        
        border-radius: $ui-border_radius-1; // 15px;
        background-color: var(--ui-background_color-1);
        border: 1px solid var(--border-color-1);
        box-shadow: $ui-box_shadow-1;
        
        @media screen and (max-width: 1023px) {
            header h1 { font-size: 28px; }
        }
        
        @media screen and (max-width: 599px) {
            margin-top: 20px;
            border-radius: $ui_interior-border_radius-1;
            width: calc(100% - 6px);
            padding: 5px;
        }        
        // animation: fadeInModal 0.3s ease-out forwards; // Animation to fade in
    }

    header {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        // margin: 0 auto;

        input, textarea { 
            // width: 100% !important;
            width: 100%;
            margin: 0;

            padding: 8px 16px;
            // border-radius: 8px;
            // font-size: 16px;
        }
        input[type="checkbox"] {
            width: auto;
        }


        button:not(.button-small) {
            height: 40px;
            font-weight: 700;
        }
    }

    // .modal-close-button {
    //     padding: 10px 15px;
    // }
}

.hidden { display: none;}

.text-secondary { color: var(--font-color-2); }
.text-third { color: var(--font-color-3); }
.text-danger { color: var(--font_danger-color-1); }
.text-success { color: var(--font_success-color-1); }
.text-warning { color: var(--font_warning-color-1); }
.text-info { color: var(--font_info-color-1); }

.nothing-found {
    @include absolute-center;
    width: 100%;
    text-align: center;
}

