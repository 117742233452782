// .profile-general-tab {
    // display: grid;
    // grid-template-columns: 330.48px 1fr;
    // gap: 10px;
// }

// .profile-user-bio {
//     position: relative;

//     margin-top: 45px;
//     padding: 20px 10px 10px 10px;

//     background-color: $bubble-background_color-1;
//     border: 1px solid $border-color-1;
//     border-radius: $ui_interior-border_radius-1;

//     &-title {
//         position: absolute;
//         background-color: $bubble-background_color-1;
//         padding: 2px 5px;
//         top: -10px;
//         left: 15px;
//         border-radius: $ui_interior-border_radius-1;
//     }

//     &-text {
//         max-height: 500px;
//         height: 100%;

//     }
// }

.profile-user-stats {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 45px;

    a {
        // margin-left: auto;

        &:not(:hover):not(:active) {
            color: var(--font_toggle-color-1);
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;

        border-bottom: 1px solid var(--border-color-2);
        padding-bottom: 5px;

        span:first-of-type {
            // font-weight: 600;
            color: var(--font-color-2);
        }

        button {
            padding: 5px 0px;
            color: var(--font_toggle-color-1);
            border: none;
            background-color: transparent !important;
        }
    }
}