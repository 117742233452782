.contact-page {
    overflow-y: scroll;
    overflow-x: hidden;
}

.contact-container {
    position: relative;
    overflow-x: hidden;
    
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    // min-height: 100%;
    min-height: 762px; // 750px;
    // max-height: 100dvh;
    // max-height: 1088px;
    max-width: 1000px; // 1247.75px;
    background-color: var(--ui_interior-background_color-1);

    border-radius: $ui-border_radius-1;
    border: 1px solid var(--border-color-1);

    // box-shadow: $ui_interior-box_shadow-1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;

    //     box-shadow: $ui-box_shadow-1;
    // }
}

.contact-form {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    .richtext-container {
        margin-bottom: 25px;
    }

    button {
        height: 40px;
        font-weight: 700;
        // width: 100%;
    }
}

.contact-indicator {
    margin-top: 5px;
}

.contact-header {    
    display: flex;
    flex-direction: column;
}

.contact-title {
    padding: 10px;
    border-bottom: 1px solid var(--border-color-1);
    background-color: var(--ui-background_color-1);


}

.contact-content {
    padding: 30px 0px;
}

.contact-footer {
    padding: 5px;
    margin-top: auto;
    border-top: 1px solid var(--border-color-1);
    background-color: var(--ui-background_color-1);
}

@media screen and (min-width: 769px) {
    .contact-form {
        padding: 0px 30px;
    }    
}

// Mobile / Tablet view
@media screen and (max-width: 768px) {

    .contact-form { padding: 0px 20px; }
}

@media screen and (max-width: 600px) {
    .contact-form { padding: 0px 10px; }

    .contact-container  { border-radius: $ui_interior-border_radius-1 }
}
