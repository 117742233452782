// Disable scrolling when a post is selected
// @media screen and (max-width: 768px) and (max-height: 750px) {
//     .home-page.ps-selected { overflow: hidden; }
// }

.home-page {
    height: calc(var(--page-height) + var(--footer-height)) !important; // Manual due to being the only page without the standard footer
}

.home-page-title {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1002.19px;

    // padding: 0px 10px;
    margin: 0px auto 30px auto;

    // @media screen and (max-width: 599px) { padding: 0px 3px; }
    
    
    h1 {
        max-width: max-content;
        
        padding: 10px;
        margin: 30px auto;
        // margin: 15vh auto;
        
        background-color: var(--ui-background_color-1);
        border-radius: $ui_interior-border_radius-1;
        border: 1px solid var(--border-color-1);
        
        @media screen and (max-width: 599px) {
            margin: 30px auto;
            padding: 5px;
        }
    }
}

.home-page-result-count-container {
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    .home-page-result-count {
        position: relative;
        z-index: 1;
    
        span {
            // position: absolute;
            // bottom: -12px;
            background-color: var(--ui-background_color-1);
            border-radius: $ui_interior-border_radius-1;
            border: 1px solid var(--border-color-1);
            padding: 2px 5px;
        }
    
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
    
            border-top: 1px solid var(--border-color-1);
            z-index: -1;
        }
    }

    .beta-badge {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        
        display: inline-block;
        padding: 2px 5px; // 4px 8px;
        background-color: #ff6600;
        font-weight: bold;
        font-size: 0.8rem;
        border: 1px solid #933b01; // #66B2FF; // var(--border-color-1);
        border-radius: $ui_interior-border_radius-1;
        text-transform: uppercase;
        // margin-bottom: 10px;
    }
}

.problemsolution-list-container {
    width: 100%;
    height: max-content;
    // padding: 0px 10px 10px 10px;
}

.no-results { @include absolute-center; }