.footer {
    // position: relative;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 100;

    height: var(--footer-height);
    padding: 5px 15px;
    background: var(--ui-background_color-1);
    text-align: center;

    border-top: 2px solid var(--border-color-1);
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

    font-size: 10px;

    @media screen and (max-width: 1023px) {
        display: none;
    }
}

