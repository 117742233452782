.reportform.modal {
    z-index: 51;

    &.success {
        text-align: center;
        .reportform-container { max-width: 450px; }
    }

    .reportform-container {
        min-height: 0;
        // max-width: 550px;
        max-width: 500px;
        // width: 100%;
    
        // text-align: center;
        
        header { margin: 0; }    
    
        h1 {width: 100%;}
    
        hr {margin: 5px 0;}
    
    }
    
    .reportform-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0;

        label {
            margin-bottom: -10px;
        }
        
        textarea, .ql-editor { 
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            min-height: 64px;
            max-height: 300px;
            padding: 10px; // 10px 5px;
            margin-bottom: 5px;
            // padding-right: 15px;

            border-radius: 4px; // 0;
        }

        .reportform-indicator {
            display: grid;
            grid-template-columns: 1fr 70px;
            gap: 5px;
    
            margin-top: -10px;
            // margin-bottom: 10px;
    
            .character-count {
                text-align: right;
            }
        }

        // .error-message { margin-top: -10px; }
    }
}

