.resetpassword-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    gap: 10px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 550px;
    padding: 10px;
    border: 1px solid var(--border-color-1);
    border-radius: 10px;
    
    background-color: var(--ui-background_color-1);
    box-shadow: $ui-box_shadow-1;
    
    &.reset-finished {
        align-items: center;
        max-width: 350px;

        & > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 15px;
        }
    }
}

.resetpassword-form {
    display: flex;
    flex-direction: column;
    // gap: 20px;

    input {
        margin-bottom: 20px;
    }

    &.validation-failed input {
        border-color: var(--font_danger-color-1); 
    }

    button {
        width: 100%;
        height: 40px;
        font-weight: 700;
    }

    p {
        margin: -15px 0 15px 5px;
    }
}