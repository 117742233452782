.settings-container {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    border: 1px solid var(--border-color-1);
    border-radius: $ui-border_radius-1;

    margin: 0 auto;
    min-height: 700px; // 100%;
    max-width: 1000px;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    background-color: var(--ui_interior-background_color-1);
}

.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    overflow: hidden;
    width: 100%;

    border-bottom: 1px solid var(--border-color-1);
    // border-radius: $ui-border_radius-1;

    background-color: var(--ui-background_color-1);

    h1, h2, p {
        padding: 10px;
    }
}

.settings-tab-links-container {
    // margin-top: 10px;
    border-top: 1px solid var(--border-color-1);
}

// Mobile / Tablet view
@media screen and (max-width: 768px) {

    .settings-content { padding: 0px 20px; }
    
    .settings-tab-links-list {
        
        li {    
            a {
                padding: 5px 10px;
            }
        }
    }    
}

.settings-content {

    width: 100%;

    padding: 20px 10px;
}

.settings-button-wrapper {
    display: flex;
    // gap: 10px;
}

.settings-form {
    .settings-save-button { margin-right: 10px; }
    
    button:not([disabled]) {
        color: var(--font-color-1); // #FFFFFF;

        &.settings-save-button {                
            background-color: var(--font_success-color-1);
            &:hover {
                // background-color: lighten(var(--font_success-color-1), 10%);
                filter: brightness(1.1);
                color: var(--font-color-1); // #FFFFFF;
            }
            &:active {
                // background-color: darken(var(--font_success-color-1), 10%);
                filter: brightness(0.9);
                color: var(--font-color-1); // #cecece;
            }
        }

        &.settings-revert-button {
            background-color: var(--font_danger-color-1);
            &:hover {
                // background-color: lighten(var(--font_danger-color-1), 10%);
                filter: brightness(1.1);
                color: var(--font-color-1); // #FFFFFF;
            }
            &:active {
                // background-color: darken(var(--font_danger-color-1), 10%);
                filter: brightness(0.9);
                color: var(--font-color-1); // #cecece;
            }
        }
    }
}

.settings-footer {
    width: 100%;
    padding: 5px;
    margin-top: auto;
    border-top: 1px solid var(--border-color-1);
    background-color: var(--ui-background_color-1);
}