.account-general-tab {
    display: flex;
    flex-direction: column;
    gap: 30px;

    // .account-overview, .account-information, .account-settings {
    & > section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
            // margin-left: auto;

            &:not(:hover):not(:active) {
                color: var(--font_toggle-color-1);
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        
        li {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;

            border-bottom: 1px solid var(--border-color-2);
            padding-bottom: 5px;

            span:first-of-type {
                // font-weight: 600;
                color: var(--font-color-2);
            }

            button {
                color: var(--font_toggle-color-1);
                border: none;
                background-color: transparent !important;

                &:not(.button-link) { padding: 5px 0px; }
            }
        }
    }

    .account-setting-title-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .account-settings-button-wrapper {
        display: flex;
        // gap: 10px;
    }

    .account-settings-form {
        .account-settings-save-button { margin-right: 10px; }
        
        button:not([disabled]) {
            color: var(--font-color-1); // #FFFFFF;

            &.account-settings-save-button {                
                background-color: var(--font_success-color-1);
                &:hover {
                    // background-color: lighten(var(--font_success-color-1), 10%);
                    filter: brightness(1.1);
                    color: var(--font-color-1); // #FFFFFF;
                }
                &:active {
                    // background-color: darken(var(--font_success-color-1), 10%);
                    filter: brightness(0.9);
                    color: var(--font-color-1); // #cecece;
                }
            }

            &.account-settings-revert-button {
                background-color: var(--font_danger-color-1);
                &:hover {
                    // background-color: lighten(var(--font_danger-color-1), 10%);
                    filter: brightness(1.1);
                    color: var(--font-color-1); // #FFFFFF;
                }
                &:active {
                    // background-color: darken(var(--font_danger-color-1), 10%);
                    filter: brightness(0.9);
                    color: var(--font-color-1); // #cecece;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .account-general-tab {
        display: grid;
        grid-template-columns: 300px 1fr; // First column fixed at 260px, second column flexible
        grid-template-rows: auto auto 1fr; // Adjusted to fit content for the first two rows and fill the rest for the third

        // Assign grid areas
        & > section:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2; // First section in the first column, first row
        }
        & > section:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2; // Second section in the first column, second row
        }
        & > section:nth-child(3) {
            grid-area: 1 / 2 / 4 / 3; // Third section in the second column, spanning all rows
        }
    }
}

