.account-page {
    overflow-y: scroll;
    overflow-x: hidden;
}

.account-container {
    position: relative;
    overflow-x: hidden;
    
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: 700px; // 100%;
    // max-height: 1088px;
    
    
    // box-shadow: $ui_interior-box_shadow-1;
    
    &.is-general {
        border-radius: $ui-border_radius-1;
        border: 1px solid var(--border-color-1);
        
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        background-color: var(--ui_interior-background_color-1);

        max-width: 1002.19px;

        .account-title { }        
    }
    
    &.is-posts {
        max-width: 1312px; // 1247.75px;
        
        .account-header {    
            margin: 0 auto;
            overflow: hidden;
            width: 100%;
            max-width: 1002.19px;
            border-radius: $ui-border_radius-1;
            border: 1px solid var(--border-color-1);
        }

        .account-tab-links-list { border-bottom: none }
    }
}

.account-header {    
    display: flex;
    flex-direction: column;
}

.account-title {
    padding: 10px;
    border-bottom: 1px solid var(--border-color-1);
    background-color: var(--ui-background_color-1);
}

.account-content {
    padding: 30px 0px;
}


.account-tab-links-list {
    overflow: hidden;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1px;

    background-color: var(--border-color-1);
    border-bottom: 1px solid var(--border-color-1);
    
    
    li {
        text-align: center;
        flex: 1 0 auto;

        a {
            display: block;
            padding: 10px 20px;                
            font-size: 14px;
            font-weight: 500;

            background-color: var(--ui-background_color-1);
            transition: all 0.1s ease;

            // &:hover, &.isActive:hover {
            //     background-color: var(--button_hover-background_color-1); 
            // }

            // &:active, &.isActive, &.isActive:active { 
            //     background-color: var(--button_active-background_color-1); 
            //     // background-color: var(--button-toggle-background_color-1); 
            // }

            &, &.isActive {

                &:hover {
                    // color: var(--font_hover-color-1);
                    // color: var(--button-text-hover-color-1);
                    background-color: var(--button_hover-background_color-1);
                }
                
                &:active {
                    // color: var(--font_active-color-1);
                    // color: var(--button-text-active-color-1);
                    background-color: var(--button_active-background_color-1);
                }
            }
            &.isActive { 
                // color: var(--font_toggle-color-1); 
                // color: var(--button-text-toggle-color-1);
                background-color: var(--button-toggle-background_color-1);
            }
        }
    }        
}

.account-footer {
    padding: 5px;
    margin-top: auto;
    border-top: 1px solid var(--border-color-1);
    background-color: var(--ui-background_color-1);
}

// Large view
// @media screen and (min-width: 1024px) {
    
//     .account-tab-links-list {
//         // max-width: 1000px;
//         // border-bottom-right-radius: $ui-border_radius-1;
//         // border-right: 1px solid var(--border-color-1);
//     }
// }

@media screen and (min-width: 769px) {
    .account-content .account-general-tab {
        padding: 0px 30px;
    }    
}

// Mobile / Tablet view
@media screen and (max-width: 768px) {

    .account-content .account-general-tab { padding: 0px 20px; }
    
    .account-tab-links-list {
        
        li {    
            a {
                padding: 5px 10px;
            }
        }
    }    
}

@media screen and (max-width: 599px) {
    .account-container {

        &.is-general { border-radius: $ui_interior-border_radius-1; }
        
        &.is-posts .account-header { border-radius: $ui_interior-border_radius-1; }
    }

    .account-content .account-general-tab { padding: 0px 10px; }

    .account-container  { border-radius: $ui_interior-border_radius-1 }
}