.about-container {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    border: 1px solid var(--border-color-1);
    border-radius: $ui-border_radius-1;

    margin: 0 auto;
    max-width: 1000px;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.about-header {
    overflow: hidden;
    width: 100%;

    border-bottom: 1px solid var(--border-color-1);
    // border-radius: $ui-border_radius-1;

    background-color: var(--ui-background_color-1);

    h1, h2 {
        padding: 10px;
    }
}

.about-tab-links-container {
    // margin-top: 10px;
    border-top: 1px solid var(--border-color-1);
}

.about-tab-links-list {
    overflow: hidden;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1px;

    // padding: 1px;

    background-color: var(--border-color-1);

    // border-bottom: 1px solid var(--border-color-1);
    
    
    li {
        text-align: center;
        flex: 1 0 auto;

        a {
            display: block;
            padding: 10px 20px;                
            font-size: 14px;
            font-weight: 500;

            background-color: var(--ui-background_color-1);
            transition: all 0.1s ease;

            &:hover, &.isActive:hover {
                background-color: var(--button_hover-background_color-1); 
            }

            &:active, &.isActive, &.isActive:active { 
                background-color: var(--button_active-background_color-1); 
            }
        }
    }        
}

// Mobile / Tablet view
@media screen and (max-width: 768px) {

    .about-content { padding: 0px 20px; }
    
    .about-tab-links-list {
        
        li {    
            a {
                padding: 5px 10px;
            }
        }
    }    
}

.about-content {

    width: 100%;

    padding: 20px 10px;

    // border: 1px solid var(--border-color-1);
    // border-radius: $ui-border_radius-1;
    background-color: var(--ui_interior-background_color-1);
    // background-color: var(--ui-background_color-1);

    h1 { margin-bottom: 26px; }
    h2, h4 { margin: 26px 0px; }
    h3 { 
        margin: 24px 0px; 
        // color: var(--font-color-2);
    }

    p { margin: 16px 0px; }

    ul, ol {
        margin: 16px 0px;
        padding-left: 24px;

        li { margin-bottom: 16px; }
        &:is(ul) li { list-style-type: disc; }
        &:is(ol) li { list-style-type: decimal; }
    }

    a {
        cursor: pointer;

        &:not(:hover):not(:active) { color: var(--font_toggle-color-1); }
        &:active { color: var(--font_active-color-1); }
        &:hover { color: var(--font_hover-color-1); }
    }

    table {
        border: 1px solid var(--border-color-1);
        border-collapse: collapse;

        th, td {
            text-align: left;
            padding: 5px 10px;
            border-bottom: 1px solid var(--border-color-1);
            &:not(:last-of-type) { border-right: 1px solid var(--border-color-1); }
        }
    }
}

.about-footer {
    width: 100%;
    padding: 5px;
    margin-top: auto;
    border-top: 1px solid var(--border-color-1);
    background-color: var(--ui-background_color-1);
}