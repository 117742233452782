:root {
  --header-height: 56px;
  --footer-height: 23px;
  --page-height: calc(100dvh - (var(--header-height) + var(--footer-height)));
}
@media (min-width: 1024px) {
  :root {
    --header-height: 70px;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
* ::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: initial !important;
}
* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background-color: var(--scrollbar-track-color);
}
* ::-webkit-scrollbar-thumb {
  background-color: var(--border-color-1);
  border-radius: 8px;
}

body {
  color: var(--font-color-1);
  font-family: sans-serif;
  background: blue;
}
body, body textarea, body input, body button, body button.button-small, body select {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

h1, h2, h3, h4 {
  font-weight: bold;
  color: var(--font-color-1);
}

h1, .h1 {
  font-size: 2.5em;
  line-height: 1.2;
}
@media (max-width: 1023px) {
  h1, .h1 {
    font-size: 2em;
  }
}
@media (max-width: 599px) {
  h1, .h1 {
    font-size: 1.5em;
  }
}

h2, .h2 {
  font-size: 2em;
  line-height: 1.3;
}
@media (max-width: 1023px) {
  h2, .h2 {
    font-size: 1.5em;
    line-height: 1.2;
  }
}
@media (max-width: 599px) {
  h2, .h2 {
    font-size: 1.25em;
  }
}

h3, .h3 {
  font-size: 1.75em;
  line-height: 1.4;
}
@media (max-width: 1023px) {
  h3, .h3 {
    font-size: 1.5em;
    line-height: 1.2;
  }
}
@media (max-width: 599px) {
  h3, .h3 {
    font-size: 1.25em;
  }
}

h4, .h4 {
  font-size: 1.5em;
  line-height: 1.2;
}
@media (max-width: 599px) {
  h4, .h4 {
    font-size: 1.25em;
  }
}

a {
  transition: color 0.3s ease;
  color: var(--font-color-1);
  text-decoration: none;
}
a:not(.link-is-btn):hover {
  color: var(--font_hover-color-1);
}
a:not(.link-is-btn).isActive {
  color: var(--font_toggle-color-1);
}
a:not(.link-is-btn):active {
  color: var(--font_active-color-1);
}
a.link-is-btn {
  transition: color 0.1s ease;
}
a.link-is-btn:hover {
  color: var(--button-text-hover-color-1);
}
a.link-is-btn.isActive {
  color: var(--button-text-toggle-color-1);
}
a.link-is-btn:active {
  color: var(--button-text-active-color-1);
}

li {
  list-style: none;
}

hr {
  border: 1px solid var(--border-color-1);
}

button, .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid var(--border-color-1);
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}
button:not([disabled]), .btn:not([disabled]) {
  color: var(--button-text-color-1);
  background-color: var(--button-background_color-1);
  cursor: pointer;
}
button:not([disabled]):not(.button-link):hover, button:not([disabled]).toggled:not(.button-link):hover, .btn:not([disabled]):not(.button-link):hover, .btn:not([disabled]).toggled:not(.button-link):hover {
  color: var(--button-text-hover-color-1);
  background-color: var(--button_hover-background_color-1);
}
button:not([disabled]):not(.button-link):active, button:not([disabled]).toggled:not(.button-link):active, .btn:not([disabled]):not(.button-link):active, .btn:not([disabled]).toggled:not(.button-link):active {
  color: var(--button-text-active-color-1);
  background-color: var(--button_active-background_color-1);
}
button:not([disabled]).button-link:hover, button:not([disabled]).toggled.button-link:hover, .btn:not([disabled]).button-link:hover, .btn:not([disabled]).toggled.button-link:hover {
  color: var(--font_hover-color-1);
}
button:not([disabled]).button-link:active, button:not([disabled]).toggled.button-link:active, .btn:not([disabled]).button-link:active, .btn:not([disabled]).toggled.button-link:active {
  color: var(--font_active-color-1);
}
button:not([disabled]).button-link.toggled, button:not([disabled]).button-link.isActive, button:not([disabled]).toggled.button-link.toggled, button:not([disabled]).toggled.button-link.isActive, .btn:not([disabled]).button-link.toggled, .btn:not([disabled]).button-link.isActive, .btn:not([disabled]).toggled.button-link.toggled, .btn:not([disabled]).toggled.button-link.isActive {
  color: var(--font_toggle-color-1);
}
button:not([disabled]).toggled, .btn:not([disabled]).toggled {
  color: var(--button-text-toggle-color-1);
  background-color: var(--button-toggle-background_color-1);
}
button.button-small, .btn.button-small {
  height: 28px;
  font-size: 14px;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
}
@media screen and (max-width: 599px) {
  button.button-small.button-with-icon span:first-of-type, .btn.button-small.button-with-icon span:first-of-type {
    display: none;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

input, select, textarea {
  width: 100%;
  height: 44px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid var(--border-color-1);
  transition: border-color 0.3s;
  background-color: var(--input-background_color-1);
  color: var(--font-color-1);
}
input:not(textarea), select:not(textarea), textarea:not(textarea) {
  border-radius: 8px;
}
input:is(textarea), select:is(textarea), textarea:is(textarea) {
  border-radius: 4px;
}
input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
  color: var(--font_placeholder-color-1);
}
input::placeholder, select::placeholder, textarea::placeholder {
  color: var(--font_placeholder-color-1);
}
input:focus, select:focus, textarea:focus {
  border-color: var(--border_focus-color-1);
  outline: none;
}

select {
  height: 44px !important;
}

textarea {
  min-height: 64px;
  height: auto;
  resize: none;
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
[data-theme=light] {
  --font-color-1: black;
  --font-color-2: #555;
  --font-color-3: #777;
  --font_hover-color-1: #66B2FF;
  --font_toggle-color-1: #007BFF;
  --font_active-color-1: #3399FF;
  --font_success-color-1: #007BFF;
  --font_info-color-1: #0056b3;
  --font_warning-color-1: #fc0;
  --font_danger-color-1: #ed6a52;
  --font_placeholder-color-1: #888;
  --ui-background_color-1: #fff;
  --ui_interior-background_color-1: rgba(244,244,244,255);
  --border-color-1: #cecece;
  --border-color-2: #cecece;
  --border_focus-color-1: #007BFF;
  --button-text-color-1: #333;
  --button-text-hover-color-1: #555;
  --button-text-active-color-1: #333;
  --button-text-toggle-color-1: black;
  --button-background_color-1: #0000;
  --button-toggle-background_color-1: #007BFF;
  --button_hover-background_color-1: #66B2FF;
  --button_active-background_color-1: #3399FF;
  --input-background_color-1: #eee;
  --bubble_input-background_color-1: #f0f0f0;
  --scrollbar-color: #404040;
  --scrollbar-track-color: #0000;
  --page-background_color-1: #f9f9f9;
  --bubble-background_color-1: #f9f9f9;
  --overlay-background_color-1: #ffffffbf;
  --fade_out-background-color-1: linear-gradient(180deg,#ccc0,#ccccccb3,#ccc);
}

:root {
  --font-color-1: #e0e0e0;
  --font-color-2: #a0a0a0;
  --font-color-3: grey;
  --font_hover-color-1: #66B2FF;
  --font_toggle-color-1: #3399FF;
  --font_active-color-1: #007BFF;
  --font_success-color-1: #007BFF;
  --font_info-color-1: #428fdc;
  --font_warning-color-1: #f4db1a;
  --font_danger-color-1: #ec5941;
  --font_placeholder-color-1: #a0a0a0;
  --ui-background_color-1: #333;
  --ui_interior-background_color-1: #2a2a2a;
  --border-color-1: #606060;
  --border-color-2: #404040;
  --border_focus-color-1: #007BFF;
  --button-text-color-1: #e0e0e0;
  --button-text-hover-color-1: #009ACD;
  --button-text-toggle-color-1: #00BFFF;
  --button-text-active-color-1: #007BB5;
  --button-background_color-1: #0000;
  --button-toggle-background_color-1: #404040;
  --button_hover-background_color-1: #505050;
  --button_active-background_color-1: #404040;
  --input-background_color-1: #404040;
  --bubble_input-background_color-1: #3d3d3d;
  --scrollbar-color: #2a2a2a;
  --scrollbar-track-color: #0000;
  --page-background_color-1: radial-gradient(circle,#2b2b2b 0%,#222 50%,#1b1b1b 100%);
  --bubble-background_color-1: #404040;
  --overlay-background_color-1: #000000bf;
  --fade_out-background-color-1: linear-gradient(180deg,#3330,#333333b3,#333);
}

.header {
  position: relative;
  z-index: 100;
  height: var(--header-height);
  background: var(--ui-background_color-1);
  border-bottom: 2px solid var(--border-color-1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 30px;
}
.header-container button, .header-container .header-link {
  display: flex;
  align-items: center;
  height: 44px;
  min-width: 44px;
  padding: 10px 15px;
  text-align: center;
  font-weight: 700;
  width: -moz-max-content;
  width: max-content;
  border: 1px solid var(--border-color-1);
  border-radius: 8px;
}
.header-container .searchbar {
  max-width: 500px;
  width: 100%;
}
.header-container .dropdownnav-content {
  width: 250px;
  top: var(--header-height);
  right: 30px;
}
.header-container .dropdownnav .show-in-tablet-view {
  display: none;
}

.header-logo, .header-logo-link {
  height: 100%;
  max-height: calc(var(--header-height) - 20px);
  width: auto;
}

.header-button-container {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.header-theme-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
}

@media screen and (max-width: 1279px) {
  .header-container {
    padding: 5px 10px;
  }
  .header-container .dropdownnav-content {
    right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .header-container {
    display: grid;
    grid-template-columns: 1fr 500px 1fr;
  }
}
@media screen and (max-width: 768px) {
  .header-container .hide-in-tablet-view {
    display: none !important;
  }
  .header-container .show-in-tablet-view {
    display: block !important;
  }
  .header-container .dropdownnav-content {
    width: 100%;
    left: 0;
    right: 0;
  }
}
.dropdownnav.open .dropdownnav-content, .dropdownnav .dropdownnav-content:focus-within {
  max-height: 311px;
  border-bottom-width: 1px;
  transition: max-height 0.3s ease-in-out, border-bottom-width 0.3s ease-in-out;
}

.dropdownnav-content {
  overflow: hidden;
  position: absolute;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s 0.1s ease-in-out;
  border: 1px solid var(--border-color-1);
  border-top: none;
  border-bottom-width: 0px;
  border-radius: 0 0 15px 15px;
  background: var(--ui_interior-background_color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.dropdownnav-content-container {
  padding: 5px 0px 5px 0px;
}
.dropdownnav-content hr {
  margin-bottom: 5px;
}

.dropdownnav-navlink-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}
.dropdownnav-navlink-button-container button {
  padding-bottom: 5px !important;
}
.dropdownnav-navlink-button-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 37px;
  background-color: var(--border-color-1);
}

.dropdownnav-navlink-list {
  display: flex;
  flex-direction: column;
}
.dropdownnav-navlink-list li {
  list-style: none;
}

.dropdownnav-navlink, .dropdownnav-navlink-sub-list-toggle {
  display: block;
  width: 100%;
  height: auto;
  padding: 0px 10px 5px 10px;
  font-weight: 700;
  font-size: 18px;
}
.dropdownnav-navlink:is(button), .dropdownnav-navlink-sub-list-toggle:is(button) {
  display: flex;
  justify-content: left;
  width: 100%;
  height: auto;
  padding: 0 10px;
  background: transparent !important;
  border: none !important;
}
.dropdownnav-navlink:is(button).toggled i, .dropdownnav-navlink-sub-list-toggle:is(button).toggled i {
  transform: rotate(180deg);
}

.dropdownnav-navlink-sub-list {
  overflow: hidden;
  border: 2px solid var(--border-color-1);
  border-left: none;
  border-right: none;
  border-bottom-width: 0px;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s 0.1s ease-in-out;
  background-color: var(--ui-background_color-1);
  margin-bottom: 5px;
}
.dropdownnav-navlink-sub-list li:first-of-type a {
  padding-top: 5px;
}
.dropdownnav-navlink-sub-list a {
  font-size: initial;
  font-weight: initial;
  padding-left: 15px;
}
.dropdownnav-navlink-sub-list.open, .dropdownnav-navlink-sub-list:focus-within {
  max-height: 200px;
  border-bottom-width: 2px;
  transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s ease-in-out;
}

.searchbar {
  position: relative;
}
.searchbar:focus .searchbar-suggestions, .searchbar:focus .searchbar-suggestions .searchbar-suggestions-arrow, .searchbar:focus-within .searchbar-suggestions, .searchbar:focus-within .searchbar-suggestions .searchbar-suggestions-arrow {
  display: block;
}

.searchbar:focus, .searchbar:focus-within, .searchbar-suggestions:focus, .searchbar-suggestions:focus-within {
  display: block;
}

.searchbar-input-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.searchbar-input-container input {
  margin: 0;
  padding-right: 48px;
}
.searchbar-input-container a {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background-color: var(--ui-background_color-1);
  border-radius: 0 8px 8px 0;
  border-left: 1px solid var(--border-color-1);
  transition: background-color 0.3s ease-in-out;
}
.searchbar-input-container a:hover {
  background-color: var(--button_hover-background_color-1);
}
.searchbar-input-container a:hover i {
  color: var(--font_hover-color-1);
}
.searchbar-input-container a:active {
  background-color: var(--button_active-background_color-1);
}
.searchbar-input-container a:active i {
  color: var(--font_active-color-1);
}
.searchbar-input-container a i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  color: var(--font-color-1);
  transition: color 0.3s ease-in-out;
}

.searchbar-suggestions {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  z-index: 1;
  background-color: var(--bubble-background_color-1);
  border: 1px solid var(--border-color-1);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.searchbar-suggestions-arrow {
  position: absolute;
  top: -13px;
  left: 30px;
  border-bottom: 13px solid var(--ui-background_color-1);
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
}
.searchbar-suggestions-arrow::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: -1px;
  left: -10px;
  border-bottom: 14px solid var(--border-color-1);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.searchbar-suggestions-header {
  text-align: end;
  font-size: 10px;
  padding: 3px 5px;
  background-color: var(--ui-background_color-1);
  border-radius: 5px;
}
.searchbar-suggestions-empty {
  display: block;
  text-align: center;
  padding: 30px;
}
.searchbar-suggestions .searchbar-suggestion {
  display: grid;
  gap: 10px;
  padding: 5px 10px;
  border-top: 1px solid var(--border-color-1);
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.searchbar-suggestions .searchbar-suggestion:hover {
  color: var(--button-text-hover-color-1);
  background-color: var(--button_hover-background_color-1);
  cursor: pointer;
}
.searchbar-suggestions .searchbar-suggestion:active {
  color: var(--button-text-active-color-1);
  background-color: var(--button_active-background_color-1);
}

.searchbar.show-suggestions .searchbar-suggestions {
  display: block;
}

.loginsignupform {
  margin-top: 10px;
}
.loginsignupform form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
}
.loginsignupform form label {
  margin-bottom: -20px;
}
.loginsignupform form input {
  margin: 0;
}
.loginsignupform form .password-input-container {
  overflow: hidden;
  position: relative;
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--border-color-1);
  transition: border-color 0.3s;
}
.loginsignupform form .password-input-container:focus-within {
  border-color: var(--border_focus-color-1);
}
.loginsignupform form .password-input-container input {
  padding-right: 48px;
  border-radius: 0;
}
.loginsignupform form .password-input-container input, .loginsignupform form .password-input-container input:focus {
  border: none;
}
.loginsignupform form .password-input-container button {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 45px;
  height: 100% !important;
  border: none;
  border-left: 1px solid var(--border-color-1);
  border-radius: 0;
}
.loginsignupform form .password-input-container button:not(:hover):not(:active) {
  background-color: var(--ui-background_color-1);
}
.loginsignupform form .date-of-birth-input {
  position: relative;
}
.loginsignupform form .date-of-birth-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 45px;
  height: 100% !important;
  cursor: pointer;
}
.loginsignupform form .date-of-birth-input::after {
  content: "\f073";
  font-family: "Font Awesome 5 Free";
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 45px;
  height: 100% !important;
  color: var(--button-text-color-1);
  background-color: var(--button-background_color-1);
}
.loginsignupform form button {
  text-align: center;
  width: 100%;
  height: 40px;
  font-weight: 700;
}

.loginsignup-state-swapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
}

.loginsignup-agree {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.loginsignup-agree input {
  height: auto;
  width: auto;
}

.loginsignup-state-swapper span, .loginsignup-state-swapper a, .loginsignup-agree span, .loginsignup-agree a {
  cursor: pointer;
}
.loginsignup-state-swapper span:not(:hover):not(:active), .loginsignup-state-swapper a:not(:hover):not(:active), .loginsignup-agree span:not(:hover):not(:active), .loginsignup-agree a:not(:hover):not(:active) {
  color: var(--font_toggle-color-1);
}
.loginsignup-state-swapper span:active, .loginsignup-state-swapper a:active, .loginsignup-agree span:active, .loginsignup-agree a:active {
  color: var(--font_active-color-1);
}
.loginsignup-state-swapper span:hover, .loginsignup-state-swapper a:hover, .loginsignup-agree span:hover, .loginsignup-agree a:hover {
  color: var(--font_hover-color-1);
}

.loginsignupform-verification {
  text-align: center;
}
.loginsignupform-verification hr {
  margin: 5px 0px;
}

.loginsignup-error input {
  border: 1px solid var(--font_danger-color-1);
}
.loginsignup-error-text {
  margin: -15px 0 0px 5px;
  color: var(--font_danger-color-1);
}

.dropdownmenu {
  position: relative;
}
.dropdownmenu.open .dropdownmenu-content, .dropdownmenu .dropdownmenu-content:focus-within {
  max-height: 50px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  transition: max-height 0.3s ease-in-out, border-bottom-width 0.3s ease-in-out, border-top-width 0.3s ease-in-out;
}

.dropdownmenu-content {
  z-index: 1;
  overflow: hidden;
  position: absolute;
  right: 0;
  margin-top: 3px;
  width: 80px;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s 0.1s ease-in-out, border-top-width 0.2s 0.1s ease-in-out;
  border: 1px solid var(--border-color-1);
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-radius: 5px;
  background: var(--ui_interior-background_color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.dropdownmenu-content button, .dropdownmenu-content a {
  display: block;
  text-align: center;
  width: 100%;
  padding: 0 5px;
  border: none;
  background-color: transparent !important;
}

.dropdownmenu-navlink-list {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 18px;
}
.dropdownmenu-navlink-list li {
  list-style: none;
}
.dropdownmenu-navlink-list a {
  display: block;
  width: 100%;
  padding-bottom: 5px;
}
.dropdownmenu-navlink-list hr {
  margin-bottom: 5px;
}

.problemsolution {
  position: relative;
  display: flex;
  margin: 10px auto 20px auto;
  padding: 10px;
  transition: max-width 0.5s ease-in-out;
  width: 100%;
  max-height: var(--page-height);
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--ui-background_color-1);
  color: var(--font-color-1);
}
@media screen and (max-width: 599px) {
  .problemsolution {
    padding: 5px;
    border-radius: 5px;
    max-height: var(--page-height);
  }
  .problemsolution .problemsolution-details-container {
    padding: 15px 5px 5px 5px;
  }
}
.problemsolution hr {
  z-index: 1;
}
.problemsolution hr:first-of-type {
  margin-top: 10px;
}
.problemsolution hr:last-of-type {
  margin-bottom: 15px;
}

.problemsolution-post-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.problemsolution-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.problemsolution-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.problemsolution-header-bottom a {
  min-height: 100%;
  padding: 5px 10px 5px 0;
}
.problemsolution-header-buttons {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 5px;
}

.problemsolution-details {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  border: 1px solid var(--border-color-1);
  border-radius: 5px;
  background-color: var(--ui_interior-background_color-1);
  box-shadow: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
}
.problemsolution-details-container {
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 20px;
}
.problemsolution-details-container hr {
  margin: 5px 0;
}
.problemsolution-details-text-wrapper {
  min-height: 200px;
}
.problemsolution-details-problem, .problemsolution-details-solution {
  position: relative;
  border: 1px solid var(--border-color-1);
  border-radius: 5px;
  padding: 20px;
  background-color: var(--bubble-background_color-1);
}
.problemsolution-details-problem .problemsolution-problem-title, .problemsolution-details-problem .problemsolution-solution-title, .problemsolution-details-solution .problemsolution-problem-title, .problemsolution-details-solution .problemsolution-solution-title {
  position: absolute;
  background-color: var(--bubble-background_color-1);
  padding: 2px 5px;
  top: -10px;
  left: 15px;
  border-radius: 5px;
  font-weight: 600;
  color: var(--font-color-2);
}
.problemsolution-details-problem .problemsolution-problem-title::before, .problemsolution-details-problem .problemsolution-solution-title::before, .problemsolution-details-solution .problemsolution-problem-title::before, .problemsolution-details-solution .problemsolution-solution-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 15px;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--border-color-1);
  border-bottom: none;
}

.problemsolution-footer {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 100px 1fr 100px;
  gap: 5px;
  margin-top: 10px;
}
.problemsolution-footer > button:last-of-type, .problemsolution-footer > a:last-of-type {
  margin-left: auto;
}
.problemsolution-footer > button:first-of-type, .problemsolution-footer > a:first-of-type {
  margin: auto;
}
@media screen and (min-width: 600px) {
  .problemsolution-footer {
    grid-template-columns: 120px 1fr 120px;
  }
  .problemsolution-footer button:last-of-type {
    margin-left: auto;
  }
}

.problemsolution-stats {
  display: flex;
  align-items: center;
  gap: 5px;
}
.problemsolution-stats div {
  display: flex;
  gap: 3px;
}
.problemsolution-stats .problemsolution-comment-count i:hover {
  color: inherit;
  cursor: default;
}

@media screen and (min-width: 1024px) {
  .home-page .problemsolution, .account-page .problemsolution, .viewproblemsolution .problemsolution {
    transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;
  }
  .home-page .problemsolution.show-details .problemsolution-details, .account-page .problemsolution.show-details .problemsolution-details, .viewproblemsolution .problemsolution.show-details .problemsolution-details {
    overflow-x: hidden;
    overflow-y: scroll;
    transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
    max-height: 536px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 769px) {
  .home-page .problemsolution.show-details .problemsolution-details-container, .account-page .problemsolution.show-details .problemsolution-details-container, .viewproblemsolution .problemsolution.show-details .problemsolution-details-container {
    padding-right: 4px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 768px) {
  .home-page .problemsolution.show-details .problemsolution-details, .account-page .problemsolution.show-details .problemsolution-details, .viewproblemsolution .problemsolution.show-details .problemsolution-details {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .home-page .problemsolution.show-details .problemsolution-details::-webkit-scrollbar, .account-page .problemsolution.show-details .problemsolution-details::-webkit-scrollbar, .viewproblemsolution .problemsolution.show-details .problemsolution-details::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .home-page .problemsolution.show-details .problemsolution-details::after, .account-page .problemsolution.show-details .problemsolution-details::after, .viewproblemsolution .problemsolution.show-details .problemsolution-details::after {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    height: 0px;
    opacity: 0;
  }
  .home-page .problemsolution.hide-details .problemsolution-details, .account-page .problemsolution.hide-details .problemsolution-details, .viewproblemsolution .problemsolution.hide-details .problemsolution-details {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
    min-height: 120px;
    max-height: 120px;
  }
  .home-page .problemsolution.hide-details .problemsolution-details:not(.moderation):hover, .account-page .problemsolution.hide-details .problemsolution-details:not(.moderation):hover, .viewproblemsolution .problemsolution.hide-details .problemsolution-details:not(.moderation):hover {
    cursor: pointer;
  }
  .home-page .problemsolution.hide-details .problemsolution-details:not(.moderation)::after, .account-page .problemsolution.hide-details .problemsolution-details:not(.moderation)::after, .viewproblemsolution .problemsolution.hide-details .problemsolution-details:not(.moderation)::after {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    height: 70px;
    opacity: 1;
  }
  .home-page .problemsolution .problemsolution-details:not(.moderation)::after, .account-page .problemsolution .problemsolution-details:not(.moderation)::after, .viewproblemsolution .problemsolution .problemsolution-details:not(.moderation)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--fade_out-background-color-1);
  }
  .home-page .problemsolution.show-comments, .account-page .problemsolution.show-comments, .viewproblemsolution .problemsolution.show-comments {
    max-width: 1247.75px;
  }
  .home-page .problemsolution.show-comments .problemsolution-post-container, .account-page .problemsolution.show-comments .problemsolution-post-container, .viewproblemsolution .problemsolution.show-comments .problemsolution-post-container {
    transition: flex 0.5s ease-in-out, padding-right 0.5s ease-in-out;
    flex: 0 0 60%;
    padding-right: 10px;
  }
  .home-page .problemsolution.show-comments .commentsbox-container, .account-page .problemsolution.show-comments .commentsbox-container, .viewproblemsolution .problemsolution.show-comments .commentsbox-container {
    transition: flex 0.5s ease-in-out, border 0.5s ease-in-out;
    flex: 0 0 40%;
    border: 1px solid var(--border-color-1);
    border-radius: 5px;
  }
  .home-page .problemsolution.hide-comments, .account-page .problemsolution.hide-comments, .viewproblemsolution .problemsolution.hide-comments {
    max-width: 1002.19px;
    width: 100%;
  }
  .home-page .problemsolution.hide-comments .problemsolution-post-container, .account-page .problemsolution.hide-comments .problemsolution-post-container, .viewproblemsolution .problemsolution.hide-comments .problemsolution-post-container {
    transition: flex 0.5s ease-in-out, padding-right 0.5s ease-in-out;
    flex: 1 0 100%;
    padding-right: 0px;
  }
  .home-page .problemsolution.hide-comments .commentsbox-container, .account-page .problemsolution.hide-comments .commentsbox-container, .viewproblemsolution .problemsolution.hide-comments .commentsbox-container {
    transition: flex 0.5s ease-in-out, border 0.5s ease-in-out;
    flex: 0 0 0%;
    border: 0px solid var(--border-color-1);
    border-radius: 5px;
  }
  .home-page .problemsolution .commentsbox-container, .account-page .problemsolution .commentsbox-container, .viewproblemsolution .problemsolution .commentsbox-container {
    box-shadow: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
  }
  .home-page .problemsolution .commentsbox-container.outside-details, .account-page .problemsolution .commentsbox-container.outside-details, .viewproblemsolution .problemsolution .commentsbox-container.outside-details {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: var(--ui_interior-background_color-1);
  }
  .home-page .problemsolution .commentsbox-container.outside-details .commentsbox-wrapper, .account-page .problemsolution .commentsbox-container.outside-details .commentsbox-wrapper, .viewproblemsolution .problemsolution .commentsbox-container.outside-details .commentsbox-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 10px;
    min-width: 387.59px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1094px) {
  .home-page .problemsolution .commentsbox-container.outside-details .button-with-icon span:first-of-type, .account-page .problemsolution .commentsbox-container.outside-details .button-with-icon span:first-of-type, .viewproblemsolution .problemsolution .commentsbox-container.outside-details .button-with-icon span:first-of-type {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .home-page .problemsolution .commentsbox-container.inside-details, .account-page .problemsolution .commentsbox-container.inside-details, .viewproblemsolution .problemsolution .commentsbox-container.inside-details {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .home-page .problemsolution.show-details .problemsolution-details, .account-page .problemsolution.show-details .problemsolution-details, .viewproblemsolution .problemsolution.show-details .problemsolution-details {
    overflow-x: hidden;
    overflow-y: scroll;
    transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
    max-height: 536px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 769px) {
  .home-page .problemsolution.show-details .problemsolution-details-container, .account-page .problemsolution.show-details .problemsolution-details-container, .viewproblemsolution .problemsolution.show-details .problemsolution-details-container {
    padding-right: 4px;
  }
}
@media screen and (max-width: 1023px) and (max-width: 768px) {
  .home-page .problemsolution.show-details .problemsolution-details, .account-page .problemsolution.show-details .problemsolution-details, .viewproblemsolution .problemsolution.show-details .problemsolution-details {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .home-page .problemsolution.show-details .problemsolution-details::-webkit-scrollbar, .account-page .problemsolution.show-details .problemsolution-details::-webkit-scrollbar, .viewproblemsolution .problemsolution.show-details .problemsolution-details::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .home-page .problemsolution.show-details .problemsolution-details::after, .account-page .problemsolution.show-details .problemsolution-details::after, .viewproblemsolution .problemsolution.show-details .problemsolution-details::after {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    height: 0px;
    opacity: 0;
  }
  .home-page .problemsolution.hide-details .problemsolution-details, .account-page .problemsolution.hide-details .problemsolution-details, .viewproblemsolution .problemsolution.hide-details .problemsolution-details {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
    min-height: 120px;
    max-height: 120px;
  }
  .home-page .problemsolution.hide-details .problemsolution-details:not(.moderation):hover, .account-page .problemsolution.hide-details .problemsolution-details:not(.moderation):hover, .viewproblemsolution .problemsolution.hide-details .problemsolution-details:not(.moderation):hover {
    cursor: pointer;
  }
  .home-page .problemsolution.hide-details .problemsolution-details:not(.moderation)::after, .account-page .problemsolution.hide-details .problemsolution-details:not(.moderation)::after, .viewproblemsolution .problemsolution.hide-details .problemsolution-details:not(.moderation)::after {
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    height: 70px;
    opacity: 1;
  }
  .home-page .problemsolution .problemsolution-details:not(.moderation)::after, .account-page .problemsolution .problemsolution-details:not(.moderation)::after, .viewproblemsolution .problemsolution .problemsolution-details:not(.moderation)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--fade_out-background-color-1);
  }
  .home-page .problemsolution .commentsbox-container.outside-details, .account-page .problemsolution .commentsbox-container.outside-details, .viewproblemsolution .problemsolution .commentsbox-container.outside-details {
    display: none;
  }
}

.viewprofile .problemsolution.show-details .problemsolution-details {
  overflow-x: hidden;
  overflow-y: scroll;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  max-height: 536px;
}
@media (min-width: 769px) {
  .viewprofile .problemsolution.show-details .problemsolution-details-container {
    padding-right: 4px;
  }
}
@media screen and (max-width: 768px) {
  .viewprofile .problemsolution.show-details .problemsolution-details {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .viewprofile .problemsolution.show-details .problemsolution-details::-webkit-scrollbar {
    display: none;
  }
}
.viewprofile .problemsolution.show-details .problemsolution-details::after {
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  height: 0px;
  opacity: 0;
}
.viewprofile .problemsolution.hide-details .problemsolution-details {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out;
  min-height: 120px;
  max-height: 120px;
}
.viewprofile .problemsolution.hide-details .problemsolution-details:not(.moderation):hover {
  cursor: pointer;
}
.viewprofile .problemsolution.hide-details .problemsolution-details:not(.moderation)::after {
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  height: 70px;
  opacity: 1;
}
.viewprofile .problemsolution .problemsolution-details:not(.moderation)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--fade_out-background-color-1);
}
.viewprofile .problemsolution .commentsbox-container.outside-details {
  display: none;
}

.quill button {
  height: auto !important;
}

.ql-toolbar {
  border-radius: 10px 10px 0 0;
  border: 1px solid var(--border-color-1) !important;
  border-bottom: none !important;
  background-color: var(--ui-background_color-1);
}
.ql-toolbar * {
  color: var(--font-color-1);
}
.ql-toolbar *.ql-active, .ql-toolbar *.ql-expanded {
  color: var(--font_hover-color-1);
}
.ql-toolbar .ql-picker.ql-expanded .ql-picker-label::before, .ql-toolbar .ql-picker:hover .ql-picker-label::before {
  color: var(--font_hover-color-1) !important;
}
.ql-toolbar .ql-picker-label.ql-active::before {
  color: var(--font_hover-color-1) !important;
}
.ql-toolbar button .ql-stroke, .ql-toolbar .ql-picker .ql-stroke {
  stroke: var(--font-color-1) !important;
}
.ql-toolbar button .ql-fill, .ql-toolbar .ql-picker .ql-fill {
  fill: var(--font-color-1) !important;
}
.ql-toolbar button:hover, .ql-toolbar .ql-picker:hover {
  background-color: transparent !important;
  outline: 1px solid var(--border-color-1) !important;
}
.ql-toolbar button.ql-active .ql-stroke, .ql-toolbar button.ql-expanded .ql-stroke, .ql-toolbar .ql-picker.ql-active .ql-stroke, .ql-toolbar .ql-picker.ql-expanded .ql-stroke {
  stroke: var(--font_hover-color-1) !important;
}
.ql-toolbar button.ql-active .ql-fill, .ql-toolbar button.ql-expanded .ql-fill, .ql-toolbar .ql-picker.ql-active .ql-fill, .ql-toolbar .ql-picker.ql-expanded .ql-fill {
  fill: var(--font_hover-color-1) !important;
}

.ql-container {
  border: none !important;
}

.ql-editor {
  min-height: 238px;
  transition: border-color 0.3s;
  border-radius: 0 0 10px 10px;
  background-color: var(--input-background_color-1);
  color: var(--font-color-1);
}
.ql-editor:not(:focus) {
  border: 1px solid var(--border-color-1);
}
.ql-editor:focus {
  border: 1px solid var(--border_focus-color-1);
}
.ql-editor.ql-blank:before {
  color: var(--font-color-2) !important;
}

.ql-tooltip {
  background-color: var(--ui-background_color-1) !important;
  border: 1px solid var(--border-color-1) !important;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1) !important;
  color: var(--font-color-1) !important;
}

.ql-action {
  color: var(--font_hover-color-1) !important;
}

.commentsbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  color: var(--font-color-1);
}
.commentsbox-wrapper::-webkit-scrollbar {
  width: 5px;
}

.commentsbox-list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0px 0px 0px;
  min-height: 50px;
}

.comment, .reply {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px 10px 10px 10px;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  background-color: var(--bubble-background_color-1);
}
.comment-text-container button, .reply-text-container button {
  border: none;
  color: var(--font-color-2);
  margin-top: 5px;
}
.comment-text-container button, .comment-text-container button:hover, .comment-text-container button:active, .reply-text-container button, .reply-text-container button:hover, .reply-text-container button:active {
  background-color: transparent;
}
.comment-text, .reply-text {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
}
.comment-text.read-more, .reply-text.read-more {
  max-height: none;
}
.comment .dropdownmenu button, .reply .dropdownmenu button {
  border: none;
  padding: 0 10px;
  height: 21px;
}

.comment-user-date, .reply-user-date {
  position: absolute;
  top: -10px;
  border-radius: var(--ui_interior-border_radius-1);
  display: flex;
  gap: 5px;
  padding: 2px 5px;
}
.comment-user-date p, .comment-user-date nav, .reply-user-date p, .reply-user-date nav {
  z-index: 1;
}
.comment-user-date::before, .reply-user-date::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 15px;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--border-color-1);
  border-bottom: none;
  background-color: var(--bubble-background_color-1);
}

.comment-stats {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.comment-buttons {
  display: flex;
  gap: 5px;
}
.comment-buttons.absolute {
  position: absolute;
  bottom: 0px;
}

.comment-likes {
  display: flex;
  gap: 3px;
}

.comment-reply {
  margin-left: auto;
}

.comment-replies-button {
  display: flex;
  align-items: center;
  gap: 3px;
}
.comment-replies-button.flip-carat i {
  transform: rotate(180deg);
}

.comment-form, .reply-form {
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.comment-form .ql-editor, .comment-form textarea, .reply-form .ql-editor, .reply-form textarea {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100% !important;
  min-height: 44px;
}
.comment-form-indicator, .reply-form-indicator {
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 5px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.comment-form-indicator .character-count, .reply-form-indicator .character-count {
  text-align: right;
}
.comment-form-buttons, .reply-form-buttons {
  display: flex;
  gap: 5px;
  justify-content: end;
}

.reply-form {
  margin-top: 10px;
}
.reply-form textarea {
  background: rgba(51, 51, 51, 0.2);
}

.comment-reply-list {
  position: relative;
  overflow: hidden;
  min-height: 0;
  max-height: 0;
  margin-top: -10px;
}
.comment-reply-list.open {
  margin-top: -5px;
  min-height: 115px;
  max-height: 100%;
}
.comment-reply-list.open hr {
  margin: 10px 0;
}
.comment-reply-list .loading-icon {
  top: calc(50% + 2px);
}

.reply {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  background: var(--bubble_input-background_color-1);
}

.reply-user-date::before {
  background-color: var(--bubble_input-background_color-1);
}

.reply-stats {
  display: flex;
  margin-top: 10px;
}
.reply-stats-likes {
  display: flex;
  gap: 3px;
}

.likebutton-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.likebutton-container .likebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.adspace {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 256px;
  max-width: 1002.19px;
  padding: 10px;
  margin: 20px auto 20px auto;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #333333;
  color: #E0E0E0;
}
.adspace h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 599px) {
  .adspace {
    padding: 5px;
    border-radius: 5px;
    height: 113px;
  }
}

.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-icon i {
  animation: spin 1s linear infinite;
}
.loading-icon i:hover {
  color: var(--font-color-1);
  cursor: default;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: var(--font_toggle-color-1) !important;
  border-radius: 50%;
  border: none;
}
@media screen and (max-width: 768px) {
  .scroll-to-top-button {
    right: 10px;
  }
}
.scroll-to-top-button:hover {
  background-color: var(--font_hover-color-1) !important;
}
.scroll-to-top-button:active {
  background-color: var(--font_active-color-1) !important;
}
.scroll-to-top-button i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.scroll-to-top-button i, .scroll-to-top-button i:hover {
  color: black;
}
.scroll-to-top-button.hide {
  transition: opacity 0.3s ease-in-out, width 0.1s 0.3s ease-in-out, height 0.1s 0.3s ease-in-out, background-color 0.1s ease-in-out;
  opacity: 0;
  width: 0px;
  height: 0px;
}
.scroll-to-top-button.show {
  transition: opacity 0.3s ease-in-out, background-color 0.1s ease-in-out;
  opacity: 1;
  width: 45px;
  height: 45px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  height: var(--footer-height);
  padding: 5px 15px;
  background: var(--ui-background_color-1);
  text-align: center;
  border-top: 2px solid var(--border-color-1);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-size: 10px;
}
@media screen and (max-width: 1023px) {
  .footer {
    display: none;
  }
}

.loginsignup.modal {
  z-index: 51;
}
.loginsignup.modal .loginsignup-container {
  min-height: 0;
  max-width: 550px;
}
.loginsignup.modal .loginsignup-container.email-verification {
  text-align: center;
  max-width: 450px;
  width: 100%;
}
.loginsignup.modal .loginsignup-container.email-verification header {
  display: grid;
  grid-template-columns: 1fr 42px;
  gap: 5px;
  margin: 0;
}
.loginsignup.modal .loginsignup-container.email-verification header h1 {
  margin-left: 47px;
}
.loginsignup.modal .loginsignup-container.email-verification hr {
  margin: 5px 0 5px 0;
}

.moderationnotice.modal {
  z-index: 51;
}
.moderationnotice.modal .moderationnotice-container {
  min-height: 0;
  max-width: 450px;
}
.moderationnotice.modal .moderationnotice-container header {
  margin: 0;
}
.moderationnotice.modal .moderationnotice-container h1 {
  width: 100%;
}
.moderationnotice.modal .moderationnotice-container hr {
  margin: 5px 0;
}
.moderationnotice.modal .moderationnotice-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.moderationnotice.modal .moderationnotice-content span {
  color: var(--font-color-2);
}
.moderationnotice.modal .moderationnotice-content p:not(:first-of-type) {
  border-top: 1px solid var(--border-color-2);
  padding-top: 10px;
}

.editcomment.modal {
  z-index: 51;
}
.editcomment.modal.success {
  text-align: center;
}
.editcomment.modal.success .editcomment-container {
  max-width: 450px;
}
.editcomment.modal .editcomment-container {
  min-height: 0;
  max-width: 500px;
}
.editcomment.modal .editcomment-container header {
  margin: 0;
}
.editcomment.modal .editcomment-container h1 {
  width: 100%;
}
.editcomment.modal .editcomment-container hr {
  margin: 5px 0;
}
.editcomment.modal .editcomment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.editcomment.modal .editcomment-form label {
  margin-bottom: -10px;
}
.editcomment.modal .editcomment-form textarea, .editcomment.modal .editcomment-form .ql-editor {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  min-height: 64px;
  max-height: 300px;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.editcomment.modal .editcomment-form .editcomment-form-indicator {
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 5px;
  margin-top: -10px;
}
.editcomment.modal .editcomment-form .editcomment-form-indicator .character-count {
  text-align: right;
}

.editusername.modal {
  z-index: 51;
}
.editusername.modal.success {
  text-align: center;
}
.editusername.modal.success .editusername-container {
  max-width: 450px;
}
.editusername.modal .editusername-container {
  min-height: 0;
  max-width: 500px;
}
.editusername.modal .editusername-container header {
  margin: 0;
}
.editusername.modal .editusername-container h1 {
  width: 100%;
}
.editusername.modal .editusername-container hr {
  margin: 5px 0;
}
.editusername.modal .editusername-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.editusername.modal .editusername-form label {
  margin-bottom: -10px;
}
.editusername.modal .editusername-form textarea, .editusername.modal .editusername-form .ql-editor {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  min-height: 64px;
  max-height: 300px;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.editusername.modal .editusername-form .editusername-form-indicator {
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 5px;
  margin-top: -10px;
}
.editusername.modal .editusername-form .editusername-form-indicator .character-count {
  text-align: right;
}

.reportform.modal {
  z-index: 51;
}
.reportform.modal.success {
  text-align: center;
}
.reportform.modal.success .reportform-container {
  max-width: 450px;
}
.reportform.modal .reportform-container {
  min-height: 0;
  max-width: 500px;
}
.reportform.modal .reportform-container header {
  margin: 0;
}
.reportform.modal .reportform-container h1 {
  width: 100%;
}
.reportform.modal .reportform-container hr {
  margin: 5px 0;
}
.reportform.modal .reportform-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.reportform.modal .reportform-content label {
  margin-bottom: -10px;
}
.reportform.modal .reportform-content textarea, .reportform.modal .reportform-content .ql-editor {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  min-height: 64px;
  max-height: 300px;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.reportform.modal .reportform-content .reportform-indicator {
  display: grid;
  grid-template-columns: 1fr 70px;
  gap: 5px;
  margin-top: -10px;
}
.reportform.modal .reportform-content .reportform-indicator .character-count {
  text-align: right;
}

.contactsuccess.modal {
  z-index: 51;
}
.contactsuccess.modal .contactsuccess-container {
  min-height: 0;
  max-width: 450px;
}
.contactsuccess.modal .contactsuccess-container header {
  margin: 0;
}
.contactsuccess.modal .contactsuccess-container h1 {
  width: 100%;
}
.contactsuccess.modal .contactsuccess-container hr {
  margin: 5px 0;
}
.contactsuccess.modal .contactsuccess-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}

.createproblemsolution-container {
  max-width: 1002.19px;
}

.createproblemsolution-form {
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color-1);
  border-radius: 5px;
  padding: 10px 4px 10px 10px;
  background-color: var(--ui_interior-background_color-1);
}
.createproblemsolution-form-title-counter {
  display: flex;
  text-align: right;
  margin-top: 5px;
}
.createproblemsolution-form-text-counter {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
}
.createproblemsolution-form-input-container label {
  display: block;
  margin-bottom: 2px;
  font-weight: 700;
}
.createproblemsolution-form-input-container .ql-toolbar {
  border-top-left-radius: 0;
}

.createproblemsolution-form-display-buttons {
  overflow: hidden;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  margin-bottom: -20px;
  border: 1px solid var(--border-color-1);
  border-bottom: none;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.createproblemsolution-form-display-buttons button {
  border: none;
  border-radius: 0;
  padding: 5px 15px;
}
.createproblemsolution-form-display-buttons button:first-of-type {
  border-right: 1px solid var(--border-color-1);
}

.createproblemsolution-error-text {
  color: var(--font_danger-color-1);
  margin-right: auto;
  text-align: left;
}

.createproblemsolution-form-buttons {
  display: flex;
  justify-content: space-between;
}
.createproblemsolution-form-buttons button {
  width: 70px !important;
}

.createproblemsolution-nothing-found {
  padding: 0;
  height: 252px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto 0;
  background-color: var(--ui_interior-background_color-1);
  border-radius: 5px;
  border: 1px solid var(--border-color-1);
}

.viewproblemsolution-container {
  display: flex;
  justify-content: center;
  max-width: 1247.75px;
  width: 100vw;
  margin: 3px;
  z-index: 50;
  margin-top: 20px;
}
.viewproblemsolution-container.no-results {
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
  max-width: 500px;
  width: 100%;
}
.viewproblemsolution-container.no-results > h1 {
  padding: 50px 0px;
  text-align: center;
  margin: 0;
  background-color: var(--ui_interior-background_color-1);
  border-radius: 5px;
  border: 1px solid var(--border-color-1);
}
.viewproblemsolution-container .problemsolution {
  margin: auto 3px;
  width: 100% !important;
}
.viewproblemsolution-container .problemsolution-details {
  transition: none !important;
}

.viewproblemsolution-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.viewproblemsolution-form-title-counter {
  display: flex;
  text-align: right;
  margin-top: 5px;
}

.viewproblemsolution-error-text {
  color: var(--font_danger-color-1);
  margin-right: auto;
  text-align: left;
}

.viewprofile-container {
  width: 100%;
  max-width: 700px;
}

.viewprofile-header {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
}
.viewprofile-header h1 {
  margin-right: auto;
}

.viewprofile-main {
  max-height: calc(var(--page-height) - 87px);
  min-height: 234px;
  height: 500px;
  border: 1px solid var(--border-color-1);
  border-radius: 5px;
  box-shadow: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--ui_interior-background_color-1);
}

.viewprofile-nav-button-container {
  z-index: 1;
  overflow: hidden;
  display: flex;
  position: absolute;
  background-color: var(--ui-background_color-1);
  border: 1px solid var(--border-color-1);
  border-top: none;
  border-left: none;
  border-radius: 5px 0px 10px 0px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
}

.viewprofile-nav-button {
  padding: 10px 20px;
  border-radius: 0;
  border: none;
  color: var(--font_color-1);
}
.viewprofile-nav-button:not(:last-of-type) {
  border-right: 1px solid var(--border-color-1);
}
.viewprofile-nav-button:hover, .viewprofile-nav-button.isActive:hover {
  background-color: var(--button_hover-background_color-1);
}
.viewprofile-nav-button:active, .viewprofile-nav-button.isActive, .viewprofile-nav-button.isActive:active {
  background-color: var(--button_active-background_color-1);
}

.profile-category-tab {
  overflow: auto;
  height: 100%;
  padding: 10px;
}

.profile-empty-tab {
  position: relative;
}
.profile-empty-tab h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-user-stats {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 45px;
}
.profile-user-stats a:not(:hover):not(:active) {
  color: var(--font_toggle-color-1);
}
.profile-user-stats ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-user-stats li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid var(--border-color-2);
  padding-bottom: 5px;
}
.profile-user-stats li span:first-of-type {
  color: var(--font-color-2);
}
.profile-user-stats li button {
  padding: 5px 0px;
  color: var(--font_toggle-color-1);
  border: none;
  background-color: transparent !important;
}

.profile-problemsolutions-tab {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
}
.profile-problemsolutions-tab .scroll-to-top-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.profile-problemsolution-container {
  background-color: var(--ui-background_color-1);
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  padding: 10px;
}
.profile-problemsolution-container > hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.profile-problemsolution-container .problem-solution {
  min-height: auto;
}

.profile-problemsolution-details-container {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  max-height: 100px;
}
.profile-problemsolution-details-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px; /* Height of the fading effect */
  background: var(--fade_out-background-color-1); /* Adjust the color to match your container's background */
}

.profile-problemsolution-title {
  display: grid;
  grid-template-columns: 1fr 95px;
  align-items: center;
  gap: 10px;
}

.problemsolution-details-problem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-problemsolution-footer {
  padding-top: 5px;
}

.home-page {
  height: calc(var(--page-height) + var(--footer-height)) !important;
}

.home-page-title {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1002.19px;
  margin: 0px auto 30px auto;
}
.home-page-title h1 {
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 10px;
  margin: 30px auto;
  background-color: var(--ui-background_color-1);
  border-radius: 5px;
  border: 1px solid var(--border-color-1);
}
@media screen and (max-width: 599px) {
  .home-page-title h1 {
    margin: 30px auto;
    padding: 5px;
  }
}

.home-page-result-count-container {
  position: relative;
}
.home-page-result-count-container .home-page-result-count {
  position: relative;
  z-index: 1;
}
.home-page-result-count-container .home-page-result-count span {
  background-color: var(--ui-background_color-1);
  border-radius: 5px;
  border: 1px solid var(--border-color-1);
  padding: 2px 5px;
}
.home-page-result-count-container .home-page-result-count::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--border-color-1);
  z-index: -1;
}
.home-page-result-count-container .beta-badge {
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: inline-block;
  padding: 2px 5px;
  background-color: #ff6600;
  font-weight: bold;
  font-size: 0.8rem;
  border: 1px solid #933b01;
  border-radius: 5px;
  text-transform: uppercase;
}

.problemsolution-list-container {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
}

.no-results {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginsignup.page .loginsignup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--ui-background_color-1);
  min-height: 0;
  max-width: calc(100% - 6px);
  width: 550px;
}
.loginsignup.page .loginsignup-container.email-verification {
  text-align: center;
  max-width: 550px;
  width: 100%;
}
.loginsignup.page .loginsignup-container.email-verification header h1 {
  width: 100%;
}
.loginsignup.page .loginsignup-container.email-verification hr {
  margin: 5px 0 5px 0;
}

.account-page {
  overflow-y: scroll;
  overflow-x: hidden;
}

.account-container {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 700px;
}
.account-container.is-general {
  border-radius: 10px;
  border: 1px solid var(--border-color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--ui_interior-background_color-1);
  max-width: 1002.19px;
}
.account-container.is-posts {
  max-width: 1312px;
}
.account-container.is-posts .account-header {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  max-width: 1002.19px;
  border-radius: 10px;
  border: 1px solid var(--border-color-1);
}
.account-container.is-posts .account-tab-links-list {
  border-bottom: none;
}

.account-header {
  display: flex;
  flex-direction: column;
}

.account-title {
  padding: 10px;
  border-bottom: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

.account-content {
  padding: 30px 0px;
}

.account-tab-links-list {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1px;
  background-color: var(--border-color-1);
  border-bottom: 1px solid var(--border-color-1);
}
.account-tab-links-list li {
  text-align: center;
  flex: 1 0 auto;
}
.account-tab-links-list li a {
  display: block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--ui-background_color-1);
  transition: all 0.1s ease;
}
.account-tab-links-list li a:hover, .account-tab-links-list li a.isActive:hover {
  background-color: var(--button_hover-background_color-1);
}
.account-tab-links-list li a:active, .account-tab-links-list li a.isActive:active {
  background-color: var(--button_active-background_color-1);
}
.account-tab-links-list li a.isActive {
  background-color: var(--button-toggle-background_color-1);
}

.account-footer {
  padding: 5px;
  margin-top: auto;
  border-top: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

@media screen and (min-width: 769px) {
  .account-content .account-general-tab {
    padding: 0px 30px;
  }
}
@media screen and (max-width: 768px) {
  .account-content .account-general-tab {
    padding: 0px 20px;
  }
  .account-tab-links-list li a {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 599px) {
  .account-container.is-general {
    border-radius: 5px;
  }
  .account-container.is-posts .account-header {
    border-radius: 5px;
  }
  .account-content .account-general-tab {
    padding: 0px 10px;
  }
  .account-container {
    border-radius: 5px;
  }
}
.account-general-tab {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.account-general-tab > section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.account-general-tab > section a:not(:hover):not(:active) {
  color: var(--font_toggle-color-1);
}
.account-general-tab > section ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.account-general-tab > section li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid var(--border-color-2);
  padding-bottom: 5px;
}
.account-general-tab > section li span:first-of-type {
  color: var(--font-color-2);
}
.account-general-tab > section li button {
  color: var(--font_toggle-color-1);
  border: none;
  background-color: transparent !important;
}
.account-general-tab > section li button:not(.button-link) {
  padding: 5px 0px;
}
.account-general-tab .account-setting-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.account-general-tab .account-settings-button-wrapper {
  display: flex;
}
.account-general-tab .account-settings-form .account-settings-save-button {
  margin-right: 10px;
}
.account-general-tab .account-settings-form button:not([disabled]) {
  color: var(--font-color-1);
}
.account-general-tab .account-settings-form button:not([disabled]).account-settings-save-button {
  background-color: var(--font_success-color-1);
}
.account-general-tab .account-settings-form button:not([disabled]).account-settings-save-button:hover {
  filter: brightness(1.1);
  color: var(--font-color-1);
}
.account-general-tab .account-settings-form button:not([disabled]).account-settings-save-button:active {
  filter: brightness(0.9);
  color: var(--font-color-1);
}
.account-general-tab .account-settings-form button:not([disabled]).account-settings-revert-button {
  background-color: var(--font_danger-color-1);
}
.account-general-tab .account-settings-form button:not([disabled]).account-settings-revert-button:hover {
  filter: brightness(1.1);
  color: var(--font-color-1);
}
.account-general-tab .account-settings-form button:not([disabled]).account-settings-revert-button:active {
  filter: brightness(0.9);
  color: var(--font-color-1);
}

@media screen and (min-width: 769px) {
  .account-general-tab {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto auto 1fr;
  }
  .account-general-tab > section:nth-child(1) {
    grid-area: 1/1/2/2;
  }
  .account-general-tab > section:nth-child(2) {
    grid-area: 2/1/3/2;
  }
  .account-general-tab > section:nth-child(3) {
    grid-area: 1/2/4/3;
  }
}
.account-posts-tab {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .account-posts-tab .problemsolution.show-comments {
    width: calc(100% - 10px);
  }
}

.account-posts-categories {
  overflow: hidden;
  display: flex;
  max-width: 1002.19px;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}
.account-posts-categories-link {
  width: 81px;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid var(--border-color-1);
  transition: all 0.2s ease !important;
}
.account-posts-categories-link:first-of-type {
  border-radius: 8px 0 0 8px;
}
.account-posts-categories-link:last-of-type {
  border-radius: 0 8px 8px 0;
}
.account-posts-categories-link:not(:first-of-type) {
  border-left: none;
}
.account-posts-categories-link:hover, .account-posts-categories-link.isActive:hover {
  background-color: var(--button_hover-background_color-1);
}
.account-posts-categories-link:active, .account-posts-categories-link.isActive:active {
  background-color: var(--button_active-background_color-1);
}
.account-posts-categories-link.isActive {
  background-color: var(--button-toggle-background_color-1);
}

.resetpassword-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 550px;
  padding: 10px;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  background-color: var(--ui-background_color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.resetpassword-container.reset-finished {
  align-items: center;
  max-width: 350px;
}
.resetpassword-container.reset-finished > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.resetpassword-form {
  display: flex;
  flex-direction: column;
}
.resetpassword-form input {
  margin-bottom: 20px;
}
.resetpassword-form.validation-failed input {
  border-color: var(--font_danger-color-1);
}
.resetpassword-form button {
  width: 100%;
  height: 40px;
  font-weight: 700;
}
.resetpassword-form p {
  margin: -15px 0 15px 5px;
}

.verifyemail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 350px;
  padding: 10px;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  background-color: var(--ui-background_color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.verifyemail-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

#notfound {
  display: flex;
  justify-content: center;
  height: var(--page-height);
}
@media screen and (max-width: 1023px) {
  #notfound {
    height: calc(var(--page-height) + var(--footer-height));
  }
}

.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.notfound-container hr {
  width: 100%;
}

.legal-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  margin: 0 auto;
  max-width: 1000px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.legal-header {
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}
.legal-header h2 {
  padding: 10px;
}

.legal-tab-links-container {
  border-top: 1px solid var(--border-color-1);
}

.legal-tab-links-list {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1px;
  background-color: var(--border-color-1);
}
.legal-tab-links-list li {
  text-align: center;
  flex: 1 0 auto;
}
.legal-tab-links-list li a {
  display: block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--ui-background_color-1);
  transition: all 0.1s ease;
}
.legal-tab-links-list li a:hover, .legal-tab-links-list li a.isActive:hover {
  background-color: var(--button_hover-background_color-1);
}
.legal-tab-links-list li a:active, .legal-tab-links-list li a.isActive, .legal-tab-links-list li a.isActive:active {
  background-color: var(--button_active-background_color-1);
}

@media screen and (max-width: 768px) {
  .legal-content {
    padding: 0px 20px;
  }
  .legal-tab-links-list li a {
    padding: 5px 10px;
  }
}
.legal-content {
  width: 100%;
  padding: 20px 10px;
  background-color: var(--ui_interior-background_color-1);
}
.legal-content h1 {
  margin-bottom: 26px;
}
.legal-content h2, .legal-content h4 {
  margin: 26px 0px;
}
.legal-content h3 {
  margin: 24px 0px;
}
.legal-content p {
  margin: 16px 0px;
}
.legal-content ul {
  margin: 16px 0px;
  padding-left: 24px;
}
.legal-content ul li {
  list-style-type: disc;
}

.legal-footer {
  width: 100%;
  padding: 5px;
  margin-top: auto;
  border-top: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

.about-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  margin: 0 auto;
  max-width: 1000px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.about-header {
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}
.about-header h1, .about-header h2 {
  padding: 10px;
}

.about-tab-links-container {
  border-top: 1px solid var(--border-color-1);
}

.about-tab-links-list {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1px;
  background-color: var(--border-color-1);
}
.about-tab-links-list li {
  text-align: center;
  flex: 1 0 auto;
}
.about-tab-links-list li a {
  display: block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--ui-background_color-1);
  transition: all 0.1s ease;
}
.about-tab-links-list li a:hover, .about-tab-links-list li a.isActive:hover {
  background-color: var(--button_hover-background_color-1);
}
.about-tab-links-list li a:active, .about-tab-links-list li a.isActive, .about-tab-links-list li a.isActive:active {
  background-color: var(--button_active-background_color-1);
}

@media screen and (max-width: 768px) {
  .about-content {
    padding: 0px 20px;
  }
  .about-tab-links-list li a {
    padding: 5px 10px;
  }
}
.about-content {
  width: 100%;
  padding: 20px 10px;
  background-color: var(--ui_interior-background_color-1);
}
.about-content h1 {
  margin-bottom: 26px;
}
.about-content h2, .about-content h4 {
  margin: 26px 0px;
}
.about-content h3 {
  margin: 24px 0px;
}
.about-content p {
  margin: 16px 0px;
}
.about-content ul, .about-content ol {
  margin: 16px 0px;
  padding-left: 24px;
}
.about-content ul li, .about-content ol li {
  margin-bottom: 16px;
}
.about-content ul:is(ul) li, .about-content ol:is(ul) li {
  list-style-type: disc;
}
.about-content ul:is(ol) li, .about-content ol:is(ol) li {
  list-style-type: decimal;
}
.about-content a {
  cursor: pointer;
}
.about-content a:not(:hover):not(:active) {
  color: var(--font_toggle-color-1);
}
.about-content a:active {
  color: var(--font_active-color-1);
}
.about-content a:hover {
  color: var(--font_hover-color-1);
}
.about-content table {
  border: 1px solid var(--border-color-1);
  border-collapse: collapse;
}
.about-content table th, .about-content table td {
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px solid var(--border-color-1);
}
.about-content table th:not(:last-of-type), .about-content table td:not(:last-of-type) {
  border-right: 1px solid var(--border-color-1);
}

.about-footer {
  width: 100%;
  padding: 5px;
  margin-top: auto;
  border-top: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

.contact-page {
  overflow-y: scroll;
  overflow-x: hidden;
}

.contact-container {
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 762px;
  max-width: 1000px;
  background-color: var(--ui_interior-background_color-1);
  border-radius: 10px;
  border: 1px solid var(--border-color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.contact-form {
  display: flex;
  flex-direction: column;
}
.contact-form .richtext-container {
  margin-bottom: 25px;
}
.contact-form button {
  height: 40px;
  font-weight: 700;
}

.contact-indicator {
  margin-top: 5px;
}

.contact-header {
  display: flex;
  flex-direction: column;
}

.contact-title {
  padding: 10px;
  border-bottom: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

.contact-content {
  padding: 30px 0px;
}

.contact-footer {
  padding: 5px;
  margin-top: auto;
  border-top: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

@media screen and (min-width: 769px) {
  .contact-form {
    padding: 0px 30px;
  }
}
@media screen and (max-width: 768px) {
  .contact-form {
    padding: 0px 20px;
  }
}
@media screen and (max-width: 600px) {
  .contact-form {
    padding: 0px 10px;
  }
  .contact-container {
    border-radius: 5px;
  }
}
.settings-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-color-1);
  border-radius: 10px;
  margin: 0 auto;
  min-height: 700px;
  max-width: 1000px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--ui_interior-background_color-1);
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}
.settings-header h1, .settings-header h2, .settings-header p {
  padding: 10px;
}

.settings-tab-links-container {
  border-top: 1px solid var(--border-color-1);
}

@media screen and (max-width: 768px) {
  .settings-content {
    padding: 0px 20px;
  }
  .settings-tab-links-list li a {
    padding: 5px 10px;
  }
}
.settings-content {
  width: 100%;
  padding: 20px 10px;
}

.settings-button-wrapper {
  display: flex;
}

.settings-form .settings-save-button {
  margin-right: 10px;
}
.settings-form button:not([disabled]) {
  color: var(--font-color-1);
}
.settings-form button:not([disabled]).settings-save-button {
  background-color: var(--font_success-color-1);
}
.settings-form button:not([disabled]).settings-save-button:hover {
  filter: brightness(1.1);
  color: var(--font-color-1);
}
.settings-form button:not([disabled]).settings-save-button:active {
  filter: brightness(0.9);
  color: var(--font-color-1);
}
.settings-form button:not([disabled]).settings-revert-button {
  background-color: var(--font_danger-color-1);
}
.settings-form button:not([disabled]).settings-revert-button:hover {
  filter: brightness(1.1);
  color: var(--font-color-1);
}
.settings-form button:not([disabled]).settings-revert-button:active {
  filter: brightness(0.9);
  color: var(--font-color-1);
}

.settings-footer {
  width: 100%;
  padding: 5px;
  margin-top: auto;
  border-top: 1px solid var(--border-color-1);
  background-color: var(--ui-background_color-1);
}

body {
  background: var(--page-background_color-1), #1b1b1b;
}

.App {
  height: 100dvh;
}
.App.modal-open {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 768px) {
  .App {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .App::-webkit-scrollbar {
    display: none;
  }
}

.page {
  position: relative;
  height: var(--page-height);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 30px 26px 30px 30px;
}
@media screen and (max-width: 1023px) {
  .page {
    height: calc(var(--page-height) + var(--footer-height));
  }
}
@media screen and (max-width: 1280px) {
  .page {
    padding: 30px 6px 30px 10px;
  }
}
@media screen and (max-width: 768px) {
  .page {
    padding: 30px 10px 60px 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .page::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .page {
    padding: 30px 3px 60px 3px;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: var(--footer-height);
  z-index: 50;
}
.modal-overlay {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: fadeIn 0.3s ease-out forwards;
  background: var(--overlay-background_color-1);
  cursor: pointer;
}
.modal-container {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 51;
  padding: 10px;
  width: calc(100% - 20px);
  min-height: 300px;
  max-height: 100%;
  border-radius: 10px;
  background-color: var(--ui-background_color-1);
  border: 1px solid var(--border-color-1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1023px) {
  .modal-container header h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 599px) {
  .modal-container {
    margin-top: 20px;
    border-radius: 5px;
    width: calc(100% - 6px);
    padding: 5px;
  }
}
.modal header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modal form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal form input, .modal form textarea {
  width: 100%;
  margin: 0;
  padding: 8px 16px;
}
.modal form input[type=checkbox] {
  width: auto;
}
.modal form button:not(.button-small) {
  height: 40px;
  font-weight: 700;
}

.hidden {
  display: none;
}

.text-secondary {
  color: var(--font-color-2);
}

.text-third {
  color: var(--font-color-3);
}

.text-danger {
  color: var(--font_danger-color-1);
}

.text-success {
  color: var(--font_success-color-1);
}

.text-warning {
  color: var(--font_warning-color-1);
}

.text-info {
  color: var(--font_info-color-1);
}

.nothing-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}/*# sourceMappingURL=App.css.map */