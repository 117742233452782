.problemsolution {
    position: relative;
    display: flex;

    margin: 10px auto 20px auto;
    padding: 10px;

    // min-height: 248px;

    @media screen and (max-width: 599px) {
        padding: 5px;
        border-radius: $ui_interior-border_radius-1;

        max-height: var(--page-height); // calc($page-height - 20px);
        // max-height: calc($page-height - 110px);        

        .problemsolution-details-container {
            padding: 15px 5px 5px 5px;
        }
    }


    transition: max-width 0.5s ease-in-out;
    width: 100%;

    max-height: var(--page-height); // calc($page-height - 20px);

    // @media screen and (max-width: 1023px) {
    //     max-height: calc(($page-height + $footer-height) - 20px);
    // }
    // max-height: 707.594px;
    // height: calc($page-height - 20px);
    // min-height: 568px;
    
    border: 1px solid var(--border-color-1);
    border-radius: $ui-border_radius-1;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    background-color: var(--ui-background_color-1);
    color: var(--font-color-1);

    hr { 
        z-index: 1;

        // margin: 10px 0px 15px 0px;

        &:first-of-type{ margin-top: 10px;}
        &:last-of-type{ margin-bottom: 15px;}
    }
}

.problemsolution-post-container {
    position: relative;
    display: flex;
    flex-direction: column;
    
    width: 100%;
}

.problemsolution-header {
    // margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin-bottom: 10px;
    
    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;        
        // margin-bottom: 5px;
    }

    &-bottom {
        a { 
            min-height: 100%;
            padding: 5px 10px 5px 0;
        }
    }
    
    &-buttons {
        align-self: flex-start;
        
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.problemsolution-details {
    overflow-x: hidden;
    overflow-y: scroll;
    
    position: relative;    
    display: flex;
    flex-direction: column;

    min-height: 120px;

    border: 1px solid var(--border-color-1);
    border-radius: $ui_interior-border_radius-1;
    background-color: var(--ui_interior-background_color-1);
    box-shadow: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    
    &-container {
        padding: 20px 10px 10px 10px;
    
    
        // background-color: $ui_interior-background_color-1;

        display: flex;
        flex-direction: column;
        height: auto;
        gap: 20px;

        hr {margin: 5px 0;}

    }

    &-text-wrapper {
        min-height: 200px;
    }
    
    &-problem, &-solution {
        position: relative;
        border: 1px solid var(--border-color-1); // #505050;
        border-radius: $ui_interior-border_radius-1;
        padding: 20px;
        background-color: var(--bubble-background_color-1);

        .problemsolution-problem-title, .problemsolution-solution-title {
            position: absolute;
            background-color: var(--bubble-background_color-1);
            padding: 2px 5px;
            top: -10px;
            left:15px;
            border-radius: $ui_interior-border_radius-1;
            
            font-weight: 600;
            color: var(--font-color-2);
            // color: #b4b4b4;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 15px;

                border-radius: $ui_interior-border_radius-1 $ui_interior-border_radius-1 0 0;
                border: 1px solid var(--border-color-1);
                border-bottom: none;
            }
        }
    }
}

.problemsolution-footer {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 100px 1fr 100px;
    gap: 5px;

    margin-top: 10px;

    & > button:last-of-type, & > a:last-of-type { margin-left: auto; }
    & > button:first-of-type, & > a:first-of-type { 
        margin: auto; 
        // span:first-of-type { display: inline !important; }
    }

    // @media screen and (max-width: 599px) {
        // display: flex;
        // justify-content: space-between;
        // grid-template-columns: 93px 1fr 93px;
    // }

    @media screen and (min-width: 600px) {
        grid-template-columns: 120px 1fr 120px;
        button:last-of-type { margin-left: auto; }
    }
}

.problemsolution-stats {

    display: flex;
    align-items: center;
    gap: 5px;

    div {
        display: flex;
        gap: 3px;
        // i {margin-top: 6px;}
    }

    .problemsolution-comment-count {
        i:hover {
            color: inherit;
            cursor: default;
        }
    }
}

// .problemsolution-showmore-button {}

@mixin problemSolution-toggleDetails {
    
    // SHOW DETAILS
    &.show-details {
        // height: 862px; // 662px;

        // PS CONTAINER
        // .problemsolution-post-container {
        //     transition: max-height 0.5s ease-in-out;
        //     max-height: 500px;
        // }

        // PS DETAILS
        .problemsolution-details {
            overflow-x: hidden;
            overflow-y: scroll;

            transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out; //, height 0.5s ease-in-out;
            // min-height: 454px;
            max-height: 536px; // 700px;
            // padding-right: 4px;
            // height: auto;

            @media (min-width: 769px) {
                &-container {
                    padding-right: 4px;
                }
            }
            @media screen and (max-width: 768px) {

                // Hide scrollbar for Webkit browsers
                &::-webkit-scrollbar {
                    display: none;
                }
                // Hide scrollbar for Firefox
                scrollbar-width: none;
                // For IE and Edge
                -ms-overflow-style: none;
            }
    
            &::after { 
                transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
                height: 0px;
                opacity: 0;
            }
        }
    }

    // HIDE DETAILS
    &.hide-details {

        // // PS CONTAINER
        // .problemsolution-post-container {
        //     transition: max-height 1s ease-in-out;
        //     max-height: 500px;
        // }

        // PS DETAILS
        .problemsolution-details {
            overflow: hidden;
            transition: max-height 0.5s ease-in-out, min-height 0.5s ease-in-out; //, height 0.5s ease-in-out;
            min-height: 120px;
            max-height: 120px; // 150px;

            &:not(.moderation) {

                &:hover { cursor: pointer; }

                // @media screen and (max-width: 599px) { max-height: 2px; }

                &::after { 
                    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
                    height: 70px;
                    opacity: 1; 
                }
            }

            // @media screen and (min-width: 768px) {

            //     transition: max-height 0.5s ease-in-out; //, height 0.5s ease-in-out;

            //     &:not(.moderation)::after { 
            //         transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
            //     }
            // }
        }            
    }

    // PS DETAILS
    .problemsolution-details {
        &:not(.moderation)::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            // height: 70px; /* Height of the fading effect */
            background: var(--fade_out-background-color-1);
            // background: var(--post-details-fade-color-1);
            // linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 1)); /* Adjust the color to match your container's background */
        }
    }
}

@mixin problemSolution-toggleComments {
    transition: max-width 0.5s ease-in-out, width 0.5s ease-in-out;

    // SHOW COMMENTS
    &.show-comments {
        max-width: 1247.75px;
        // width: calc(100% - 26px);
        // width: calc(100vw - 26px);

        // PS CONTAINER
        .problemsolution-post-container {
            transition: flex 0.5s ease-in-out, padding-right 0.5s ease-in-out;
            flex: 0 0 60%;
            padding-right: 10px;
        }
        
        // COMMENTSBOX
        .commentsbox-container {
            transition: flex 0.5s ease-in-out, border 0.5s ease-in-out;
            flex: 0 0 40%;

            border: 1px solid var(--border-color-1);
            border-radius: $ui_interior-border_radius-1;
        }
    }
    // HIDE COMMENTS
    &.hide-comments {
        max-width: 1002.19px;
        width: 100%; // 1002.19px;

        // PS CONTAINER
        .problemsolution-post-container {
            transition: flex 0.5s ease-in-out, padding-right 0.5s ease-in-out;
            flex: 1 0 100%;
            padding-right: 0px;
        }

        // COMMENTSBOX
        .commentsbox-container {
            transition: flex 0.5s ease-in-out, border 0.5s ease-in-out;
            flex: 0 0 0%;

            border: 0px solid var(--border-color-1);
            border-radius: $ui_interior-border_radius-1;
        }
    }
}

// Details AND Comment functionality in larger views
@mixin problemSolution-largeView {
    @include problemSolution-toggleDetails();
    @include problemSolution-toggleComments();

    .commentsbox-container {
        box-shadow: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);

        &.outside-details {
            position: relative;
        
            overflow-x: hidden;
            overflow-y: visible;
        
            background-color: var(--ui_interior-background_color-1);
    
            .commentsbox-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                padding: 10px 10px;
                min-width: 387.59px;
            }
    
            @media (max-width: 1094px) {
                .button-with-icon span:first-of-type { display: none; }
            }
        }
    
        &.inside-details { display: none; }
    }
}

// Only Details functionality in smaller views
@mixin problemSolution-smallView {
    @include problemSolution-toggleDetails();

    .commentsbox-container {
        &.outside-details {display: none;}    
        // &.inside-details { padding-bottom: 10px; }
    }
}

.home-page, .account-page, .viewproblemsolution {
    .problemsolution {
        @media screen and (min-width: 1024px) {
            @include problemSolution-largeView();
        }
        @media screen and (max-width: 1023px) {
            @include problemSolution-smallView();
        }
    }
}

.viewprofile .problemsolution {
    @include problemSolution-smallView();
}

// @mixin flex-container($direction: row, $justify: space-between, $align: center) {
//     display: flex;
//     flex-direction: $direction;
//     justify-content: $justify;
//     align-items: $align;
// }