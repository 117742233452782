.adspace {
    position: relative;
    display: flex;
    flex-direction: column;

    height: 256px;
    max-width: 1002.19px;

    padding: 10px;
    margin: 20px auto 20px auto;

    border: 1px solid var(--border-color-1);
    border-radius: $ui-border_radius-1;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    background-color: #333333;
    color: #E0E0E0;

    h1 { @include absolute-center; }

    @media screen and (max-width: 599px) {
        padding: 5px;
        border-radius: $ui_interior-border_radius-1;
        height: 113px;
    }
}

