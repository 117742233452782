.loading-icon {
    @include absolute-center;
    // z-index: 51;
    
    i {
        animation: spin 1s linear infinite;

        &:hover {
            color: var(--font-color-1);
            cursor: default;
        }
    }
}

