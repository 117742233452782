.loginsignup.page {

    .loginsignup-container {
        @include absolute-center;
        background-color: var(--ui-background_color-1);

        min-height: 0;
        max-width: calc(100% - 6px);  
        width: 550px;

        &.email-verification {
            header h1 {width: 100%;}
        
            text-align: center;
        
            hr {margin: 5px 0 5px 0;}
        
            max-width: 550px;
            width: 100%;
        }
    }
}


