:root {
    --header-height: 56px; // 70px; // 64px;
    --footer-height: 23px; // 38px; // 32px;
    --page-height: calc(100dvh - (var(--header-height) + var(--footer-height)));

    @media (min-width: 1024px) {
        --header-height: 70px; // 84px; // 70px; // 64px;
    }
}

// $header-height: 56px; // 70px; // 64px;
// $footer-height: 23px; // 38px; // 32px;

// $page-height: calc(100dvh - ($header-height + $footer-height));
// $page-height: calc(100vh - ($header-height + $footer-height));

// COLORS ----------------------------------------------

// Font 
// $font-color-1: #E0E0E0; // Lighter grey for better readability
// $font-color-2: #A0A0A0; // #89888d;
// $font-color-3: #808080; // #89888d;

// $font_hover-color-1: #50fa9f;
// $font_toggle-color-1: #49e090;
// $font_active-color-1: #42c680;

// $font_success-color-1: #2da160;
// $font_info-color-1: #428fdc;
// $font_warning-color-1: #f4db1a; // #c17d10;
// $font_danger-color-1: #ec5941; // #ec523e;

// $font_placeholder-color-1: #A0A0A0;

// // UI
// $ui-background_color-1: #333333;
$ui-box_shadow-1: 0 2px 5px rgba(0, 0, 0, 0.1); // 0.5
$ui-border_radius-1: 10px;

// $ui_interior-background_color-1: #2a2a2a;
$ui_interior-box_shadow-1: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
$ui_interior-border_radius-1: 5px;

// // Border
// $border-color-1: #606060; // #505050
// $border-color-2: #404040;
// $border_focus-color-1: #50fa9f;

// // Button
// $button_hover-background_color-1: #505050;
// $button_active-background_color-1: #404040;

// // Input
// $input-background_color-1: #404040;
// $bubble_input-background_color-1: #3333; // rgba(51, 51, 51, 0.2);

// // Misc
// $page-background_color-1: radial-gradient( circle, #2b2b2b 0%, #222222 50%, #1b1b1b 100%);
// $bubble-background_color-1: #404040;
// $overlay-background_color-1: rgba(0, 0, 0, 0.75);
// $fade_out-background-color-1: linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 1));

// GENERIC ---------------------------------------------

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    // SCROLLBAR STUFF
    // https://medium.com/frontend-development-with-js/how-to-hide-scrollbar-and-visible-only-scrolling-79cc3472e503
    
    // scrollbar-width: thin; /* Firefox */
    // -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: initial !important; // transparent 
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 8px; 
        background-color: var(--scrollbar-track-color); // transparent; // #2a2a2a;
    }
    ::-webkit-scrollbar-thumb {
        background-color: var(--border-color-1);
      border-radius: 8px;
    }
}

// FONT STYLES

body {
    color: var(--font-color-1);
    font-family: sans-serif;

    
    background: blue; //var(--page_background_color-1);
    // padding: 0 10px; // MAIN PAGE SIDE PADDING
    
    &, textarea, input, button, button.button-small, select {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.5px;
        
        // @media screen and (max-width: 599px) {            
        //     font-size: 12px;
        // }
    }
}

h1, h2, h3, h4 {
    // font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: var(--font-color-1);
    // margin-bottom: 0.5em;
}

h1, .h1 {
    font-size: 2.5em; // 40px
    line-height: 1.2;

    @media (max-width: 1023px) {
        font-size: 2em; // 32px
    }
    @media (max-width: 599px) {
        font-size: 1.5em; // 24px
    }
}

h2, .h2 {
    font-size: 2em; // 32px
    line-height: 1.3;

    @media (max-width: 1023px) {
        font-size: 1.5em; // 24px
        line-height: 1.2;
    }
    @media (max-width: 599px) {
        font-size: 1.25em; // 18px
    }
}

h3, .h3 {
    font-size: 1.75em; // 28px
    line-height: 1.4;

    @media (max-width: 1023px) {
        font-size: 1.5em; // 24px
        line-height: 1.2;
    }
    @media (max-width: 599px) {
        font-size: 1.25em; // 16px
    }
}

h4, .h4 {
    font-size: 1.5em;
    line-height: 1.2;

    @media (max-width: 599px) {
        font-size: 1.25em; // 16px
    }
}

a {
    transition: color 0.3s ease;
    color: var(--font-color-1);
    text-decoration: none;
    
    &:not(.link-is-btn) {
        &:hover { color: var(--font_hover-color-1); }
        &.isActive { color: var(--font_toggle-color-1); }
        &:active { color: var(--font_active-color-1); }
    }

    &.link-is-btn {
        transition: color 0.1s ease;
        &:hover { color: var(--button-text-hover-color-1); }
        &.isActive { color: var(--button-text-toggle-color-1); }
        &:active { color: var(--button-text-active-color-1); }

        // &, &.isActive {

        //     &:hover {
        //         color: var(--button-text-hover-color-1);
        //     }
            
        //     &:active {
        //         color: var(--button-text-active-color-1);
        //     }
        // }
        // &.isActive {
        //     color: var(--button-text-toggle-color-1);
        // }
    }

    // &:hover { color: var(--font_hover-color-1); }
    // &.isActive { color: var(--font_toggle-color-1); }
    // &:active { color: var(--font_active-color-1); }
}

// MISC ------------------------------------------------

li { list-style: none; }

hr { border: 1px solid var(--border-color-1) }

// BUTTONS ---------------------------------------------

button, .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    border: 1px solid var(--border-color-1);
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;

    
    
    &:not([disabled]) {
        color: var(--button-text-color-1);
        // color: var(--font-color-1);
        background-color: var(--button-background_color-1);
        // background-color: transparent; // $primary-button-background-color;
        
        cursor: pointer;
        
        
        &, &.toggled {

            &:not(.button-link) {
                &:hover {
                    // color: var(--font_hover-color-1);
                    color: var(--button-text-hover-color-1);
                    background-color: var(--button_hover-background_color-1);
                }
                
                &:active {
                    // color: var(--font_active-color-1);
                    color: var(--button-text-active-color-1);
                    background-color: var(--button_active-background_color-1);
                }                
            }
            &.button-link {
                &:hover {
                    color: var(--font_hover-color-1);
                }
                &:active {
                    color: var(--font_active-color-1);
                }
                &.toggled, &.isActive {
                    color: var(--font_toggle-color-1);
                }
            }

        }
        &.toggled { 
            // color: var(--font_toggle-color-1); 
            color: var(--button-text-toggle-color-1);
            background-color: var(--button-toggle-background_color-1);
        }
    }

    &.button-small {
        height: 28px;
        font-size: 14px;
        width: max-content;

        // background-color: transparent;
        padding: 5px 10px;

        &.button-with-icon {
            @media screen and (max-width: 599px) {
                span:first-of-type {display: none;}
            }
        }
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}


// .btn {
//     display: block;
//     padding: 10px 20px;                
//     font-size: 14px;
//     font-weight: 500;

//     background-color: $ui-background_color-1;
//     transition: all 0.1s ease;

//     &:hover, &.isActive:hover {
//         background-color: $button_hover-background_color-1; 
//     }

//     &:active, &.isActive, &.isActive:active { 
//         background-color: $button_active-background_color-1; 
//     }
// }

// INPUTS ----------------------------------------------

input, select, textarea {
    width: 100%; // Full-width by default
    height: 44px;
    padding: 10px;
    margin-bottom: 15px;

    font-size: 16px;
    
    border: 1px solid var(--border-color-1);
    transition: border-color 0.3s;

    &:not(textarea) { border-radius: 8px; }
    &:is(textarea) { border-radius: 4px; }

    background-color: var(--input-background_color-1);
    color: var(--font-color-1);

    &::placeholder {
        color: var(--font_placeholder-color-1);
    }

    &:focus {
        border-color: var(--border_focus-color-1);
        outline: none; // Remove default focus outline and replace with border color change
    }
}

select { height: 44px !important; }

// select:focus {
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
// }

textarea { 
    min-height: 64px; 
    height: auto;
    resize: none;
    overflow: hidden;
    // max-height: 256px;
}

// ANIMATIONS ------------------------------------------

@keyframes fadeIn {
    from { opacity: 0.1; }
    to { opacity: 1; }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

// MIXINS ----------------------------------------------

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin page-height {
    
    height: var(--page-height); // $page-height; //Header + Footer
    // margin: $header-height 0 $footer-height 0;

    @media screen and (max-width: 1023px) {
        height: calc(var(--page-height) + var(--footer-height));
        // margin: $header-height 0 0 0;
    }
}

