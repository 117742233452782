// .createproblemsolution {}

.createproblemsolution-container {
    max-width: 1002.19px;
}

.createproblemsolution-form {
    overflow-x: hidden;
    overflow-y: scroll;

    box-shadow: $ui_interior-box_shadow-1;

    border: 1px solid var(--border-color-1);
    border-radius: $ui_interior-border_radius-1;
    padding: 10px 4px 10px 10px;
    background-color: var(--ui_interior-background_color-1);

    &-title-counter {
        display: flex;
        text-align: right;
        margin-top: 5px;
    }

    &-text-counter {
        display: flex;
        justify-content: flex-end;
        margin-top: -15px;
    }

    &-input-container {
        label {
            display: block;
            margin-bottom: 2px;
            font-weight: 700;
        }

        .ql-toolbar {
            border-top-left-radius: 0;
        }

    }
}

.createproblemsolution-form-display-buttons {    
    overflow: hidden;
    display: flex;
    width: fit-content;
    min-height: 40px;
    margin-bottom: -20px;

    border: 1px solid var(--border-color-1);
    border-bottom: none;
    border-radius: $ui-border_radius-1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    button {
        border: none;
        border-radius: 0;
        padding: 5px 15px;

        &:first-of-type { border-right: 1px solid var(--border-color-1);}
    }
}

.createproblemsolution-error-text {
    color: var(--font_danger-color-1);
    margin-right: auto;
    text-align: left;
}

.createproblemsolution-form-buttons {
    display: flex;
    justify-content: space-between;

    button { width: 70px !important; }
}

.createproblemsolution-nothing-found {
    padding: 0;
    height: 252px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto 0;
    background-color: var(--ui_interior-background_color-1);
    border-radius: $ui_interior-border_radius-1;
    border: 1px solid var(--border-color-1);
}

