.account-posts-tab {
    display: flex;
    flex-direction: column;
    // gap: 20px;

    // padding: 0 5px;

    @media screen and (min-width: 1024px) {
        .problemsolution.show-comments { width: calc(100% - 10px); }
    }
}

.account-posts-categories {
    overflow: hidden;
    display: flex;

    max-width: 1002.19px;
    width: 100%;
    
    margin: auto;
    margin-bottom: 20px;
    
    &-link {
        width: 81px;
        text-align: center;
        
        padding: 5px 10px;
        border: 1px solid var(--border-color-1);
        
        &:first-of-type { border-radius: 8px 0 0 8px; }
        &:last-of-type { border-radius: 0 8px 8px 0; }

        // &:not(:last-of-type) { border-right: 1px solid $border-color-1; }
        &:not(:first-of-type) { border-left: none; }

        transition: all 0.2s ease !important;

        &:hover, &.isActive:hover {
            background-color: var(--button_hover-background_color-1); 
        }

        &:active, &.isActive:active { 
            background-color: var(--button_active-background_color-1); 
        }

        &.isActive { 
            background-color: var(--button-toggle-background_color-1); 
        }
    }

}

