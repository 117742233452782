.dropdownmenu {
    position: relative;

    &.open .dropdownmenu-content, .dropdownmenu-content:focus-within {        
        max-height: 50px;
        border-top-width: 1px;
        border-bottom-width: 1px;
        transition: max-height 0.3s ease-in-out, border-bottom-width 0.3s ease-in-out, border-top-width 0.3s ease-in-out;
    }
}
    
.dropdownmenu-content {
    z-index: 1;

    overflow: hidden;
    position: absolute;

    right: 0;
    margin-top: 3px;

    width: 80px;
    max-height: 0;
    transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s 0.1s ease-in-out, border-top-width 0.2s 0.1s ease-in-out;

    
    border: 1px solid var(--border-color-1);
    border-bottom-width: 0px;
    border-top-width: 0px;
    
    border-radius: $ui_interior-border_radius-1;
    background: var(--ui_interior-background_color-1);
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    
    button, a {
        display: block;
        text-align: center;
        width: 100%;
        padding: 0 5px;
        border: none;
        background-color: transparent !important;
    }
}

.dropdownmenu-navlink-list {
    display: flex;
    flex-direction: column;
    // gap: 5px;

    padding: 5px 10px;

    font-weight: 700;
    font-size: 18px;

    li {list-style: none;}
    a {
        display: block;
        width: 100%;
        padding-bottom: 5px;
    }

    hr { margin-bottom: 5px;}
}

