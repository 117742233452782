// .viewprofile {
//     @include display-as-modal;
// }

.viewprofile-container {
    width: 100%;
    max-width: 700px;
}


.viewprofile-header {
    display: flex;
    // justify-content: space-between;
    gap: 5px;
    
    margin-bottom: 15px;

    h1 { margin-right: auto; }
}

.viewprofile-main {
    max-height: calc(var(--page-height) - 87px);
    min-height:234px;
    height: 500px;    
    
    border: 1px solid var(--border-color-1);
    border-radius: $ui_interior-border_radius-1;    
    
    box-shadow: $ui_interior-box_shadow-1;
    background-color: var(--ui_interior-background_color-1);
}

.viewprofile-nav-button-container {
    z-index: 1;
    overflow: hidden;
    display: flex;
    position: absolute;
    // top: 57px; // 62px;
    // left: 10px;

    // padding: 10px 20px;
    
    background-color: var(--ui-background_color-1);
    border: 1px solid var(--border-color-1);
    border-top: none;
    border-left: none;
    
    border-radius: 5px 0px 10px 0px;
    
    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
}

.viewprofile-nav-button {
    padding: 10px 20px;
    border-radius: 0;
    border: none;
    
    &:not(:last-of-type) { border-right: 1px solid var(--border-color-1); }

    // &.selected {
    //     background-color: var(--button-toggle-background_color-1);
    //     color: var(--font_hover-color-1);
    //     // cursor: default;
    // }
    color: var(--font_color-1);

    &:hover, &.isActive:hover {
        // color: var(--button-text-hover-color-1);
        background-color: var(--button_hover-background_color-1); 
    }
    
    &:active, &.isActive, &.isActive:active {
        // color: var(--button-text-active-color-1);
        background-color: var(--button_active-background_color-1);
    }
}

.profile-category-tab {
    overflow: auto;
    // background-color: #2a2a2a;
    height: 100%;
    padding: 10px;
    // padding-top: 35px;
}

.profile-empty-tab {
    position: relative;

    h1 { @include absolute-center; }
}