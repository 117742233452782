.dropdownnav {
    // position: relative;

    &.open .dropdownnav-content, .dropdownnav-content:focus-within  { // , &:focus-within

        max-height: 311px; // 200px;
        border-bottom-width: 1px;
        transition: max-height 0.3s ease-in-out, border-bottom-width 0.3s ease-in-out;

        // @media screen and (max-width: 768px) {
        //     max-height: 232px;
        // }
    }
}
    
.dropdownnav-content {
    overflow: hidden;
    position: absolute;

    max-height: 0;
    transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s 0.1s ease-in-out;
    
    border: 1px solid var(--border-color-1);
    border-top: none;
    border-bottom-width: 0px;

    border-radius: 0 0 15px 15px;
    background: var(--ui_interior-background_color-1);

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    &-container { padding: 5px 0px 5px 0px; }    
    // &-container { padding: 10px 10px 5px 10px; }    

    // hr { margin: 0px 10px 5px 10px;}
    // hr { margin: 0 10px 8px 10px;}
    hr { margin-bottom: 5px;}
}

.dropdownnav-navlink-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;

    // a { border-right: 2px solid var(--border-color-1); }
    button { padding-bottom: 5px !important; }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 2px;
        height: 37px;
        background-color: var(--border-color-1);
    }
}

.dropdownnav-navlink-list {
    display: flex;
    flex-direction: column;
    // gap: 5px;

    // padding: 5px 10px;

    // font-weight: 700;
    // font-size: 18px;

    li {
        list-style: none;

        // &:not(:last-of-type) {
        //     border-bottom: 1px solid var(--border-color-2);
        // }
    }
    
    // hr { margin-bottom: 5px;}
}

.dropdownnav-navlink, .dropdownnav-navlink-sub-list-toggle {
    display: block;
    width: 100%;
    height: auto;
    padding: 0px 10px 5px 10px;

    font-weight: 700;
    font-size: 18px;

    // &:not(:last-of-type) {
    //     border-bottom: 1px solid var(--border-color-2);
    // }

    &:is(button) {

        &.toggled i {
            transform: rotate(180deg);
        }

        display: flex;
        justify-content: left;

        width: 100%;
        height: auto;
        padding: 0 10px;

        background: transparent !important;
        border: none !important;
    }
}

.dropdownnav-navlink-sub-list {
    overflow: hidden;
    // position: absolute;
    
    border: 2px solid var(--border-color-1);
    border-left: none;
    border-right: none;

    border-bottom-width: 0px;

    max-height: 0;
    transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s 0.1s ease-in-out;

    background-color: var(--ui-background_color-1); // var(--bubble-background_color-1);

    margin-bottom: 5px;

    li:first-of-type a {
        padding-top: 5px;
    }

    a {
        font-size: initial;
        font-weight: initial;
        padding-left: 15px;
    }

    &.open, &:focus-within {
        max-height: 200px;        
        // padding-top: 5px;
        border-bottom-width: 2px;

        transition: max-height 0.3s ease-in-out, border-bottom-width 0.2s ease-in-out;
    }
}