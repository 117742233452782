.scroll-to-top-button {
    position: fixed;
    bottom: 10px; // 30px; // 60px;
    right: 20px; // 50px;

    @media screen and (max-width: 768px) {
        right: 10px;
    }

    // background-color: #50fa9f !important; // #66B2FF !important;
    background-color: var(--font_toggle-color-1) !important;

    border-radius: 50%;
    // border-radius: inherit;

    border: none;
    
    // &:hover { background-color: darken(#50fa9f, 10%) !important; }

    &:hover { background-color: var(--font_hover-color-1) !important; }
    &:active { background-color: var(--font_active-color-1) !important; }
    
    i {
        &, &:hover { color: black; }

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }


    &.hide { 
        transition: opacity 0.3s ease-in-out, width 0.1s 0.3s ease-in-out, height 0.1s 0.3s ease-in-out, background-color 0.1s ease-in-out;
        opacity: 0;
        width: 0px;
        height: 0px;
    }
    &.show {
        transition: opacity 0.3s ease-in-out, background-color 0.1s ease-in-out;
        opacity: 1;
        width: 45px;
        height: 45px;

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
}

