.profile-problemsolutions-tab {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    padding-top: 45px;

    .scroll-to-top-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}

.profile-problemsolution-container {
    background-color: var(--ui-background_color-1);
    border: 1px solid var(--border-color-1);
    border-radius: $ui-border_radius-1;
    padding: 10px;

    & > hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .problem-solution {
        min-height: auto;
    }
}

.profile-problemsolution-details-container {
    position: relative;
    overflow: hidden;
    padding: 10px 0;
    max-height: 100px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px; /* Height of the fading effect */
        
        background: var(--fade_out-background-color-1); /* Adjust the color to match your container's background */
    }
}

.profile-problemsolution-title {
    display: grid;
    grid-template-columns: 1fr 95px;
    align-items: center;
    gap: 10px;
}

.problemsolution-details-problem {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-problemsolution-footer {
    padding-top: 5px;
}