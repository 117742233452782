.quill {
    button {height: auto !important;}
}

.ql-toolbar {
    border-radius: 10px 10px 0 0;
    border: 1px solid var(--border-color-1) !important;
    border-bottom: none !important;

    background-color: var(--ui-background_color-1);

    * { 
        color: var(--font-color-1);

        &.ql-active, &.ql-expanded {
            color: var(--font_hover-color-1);
        }
    }

    .ql-picker.ql-expanded, .ql-picker:hover {

        .ql-picker-label::before {
            color: var(--font_hover-color-1) !important;
        }
    }

    .ql-picker-label.ql-active::before {
        color: var(--font_hover-color-1) !important;
    }

    button, .ql-picker {

        .ql-stroke { stroke: var(--font-color-1) !important; }        
        .ql-fill { fill: var(--font-color-1) !important; }

        &:hover {
            background-color: transparent !important;
            outline: 1px solid var(--border-color-1) !important;
            // .ql-stroke { stroke: var(--button-text-hover-color-1) !important; }        
            // .ql-fill { fill: var(--button-text-hover-color-1) !important; }
        }
        &.ql-active, &.ql-expanded {
            .ql-stroke { stroke: var(--font_hover-color-1) !important; }        
            .ql-fill { fill: var(--font_hover-color-1) !important; }
        }

    }
}

.ql-container { border: none !important; }

.ql-editor {
    min-height: 238px; // 176px;

    transition: border-color 0.3s;
    border-radius: 0 0 10px 10px;
    background-color: var(--input-background_color-1);
    
    color: var(--font-color-1);

    &:not(:focus) {
        border: 1px solid var(--border-color-1);
    }
    
    &:focus {
        border: 1px solid var(--border_focus-color-1);
    }

    &.ql-blank:before {
        color: var(--font-color-2) !important; // rgb(255 255 255 / 60%)
    }
}

.ql-tooltip {
    background-color: var(--ui-background_color-1) !important;
    border: 1px solid var(--border-color-1) !important;
    border-radius: $ui-border_radius-1;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1) !important;
    color: var(--font-color-1) !important;
}

.ql-action {
    color: var(--font_hover-color-1) !important;
}

