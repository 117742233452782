// COLORS -----------------------------------------------
// :root { // [data-theme='dark'] // :root
//     // Font 
//     --font-color-1: #E0E0E0; // Lighter grey for better readability
//     --font-color-2: #A0A0A0; // #89888d;
//     --font-color-3: #808080; // #89888d;

//     --font_hover-color-1: #50fa9f;
//     --font_toggle-color-1: #49e090;
//     --font_active-color-1: #42c680;

//     --font_success-color-1: #2da160;
//     --font_info-color-1: #428fdc;
//     --font_warning-color-1: #f4db1a; // #c17d10;
//     --font_danger-color-1: #ec5941; // #ec523e;

//     --font_placeholder-color-1: #A0A0A0;

//     // UI
//     --ui-background_color-1: #333333;
//     // --ui-box_shadow-1: 0 2px 5px rgba(0, 0, 0, 0.1); // 0.5
//     // --ui-border_radius-1: 10px;

//     --ui_interior-background_color-1: #2a2a2a;
//     // --ui_interior-box_shadow-1: inset 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
//     // --ui_interior-border_radius-1: 5px;

//     // Border
//     --border-color-1: #606060; // #505050
//     --border-color-2: #404040;
//     --border_focus-color-1: #50fa9f;

//     // Button
//     --button-text-color-1: #E0E0E0;
//     --button-text-hover-color-1: #50fa9f;
//     --button-text-active-color-1: #42c680;
//     --button-text-toggle-color-1: #49e090;

//     --button-background_color-1: transparent;
//     --button-toggle-background_color-1: #404040;
//     --button_hover-background_color-1: #505050;
//     --button_active-background_color-1: #404040;

//     // Input
//     --input-background_color-1: #404040;
//     --bubble_input-background_color-1: #3d3d3d; // #3333; // rgba(51, 51, 51, 0.2);

//     // Misc
//     --scrollbar-color: #2a2a2a;
//     --scrollbar-track-color: transparent;
//     --page-background_color-1: radial-gradient( circle, #2b2b2b 0%, #222222 50%, #1b1b1b 100%);
//     --bubble-background_color-1: #404040;
//     --overlay-background_color-1: rgba(0, 0, 0, 0.75);
//     --fade_out-background-color-1: linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 1));
//     // --post-details-fade-color-1: linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 1)); /* Adjust the color to match your container's background */
// }

// [data-theme='light'] {
//     --font-color-1: #333333; // Darker grey for better readability on light background
//     --font-color-2: #555555;
//     --font-color-3: #777777;

//     // --font_hover-color-1: #0074d9; // Blue
//     // --font_toggle-color-1: #0056b3;
//     // --font_active-color-1: #004080;

//     --font_hover-color-1: #62D2A2; // #9DF3C4;
//     --font_toggle-color-1: #62D2A2;
//     --font_active-color-1: #1FAB89;

//     --font_success-color-1: #37c383;
//     --font_info-color-1: #0056b3;
//     --font_warning-color-1: #FFCC00;
//     --font_danger-color-1: #ed6a52;

//     --font_placeholder-color-1: #888888;

//     // UI
//     --ui-background_color-1: #FFFFFF; // #e6e6e6;
//     --ui_interior-background_color-1: #FFFFFF; // #cecece;

//     // Border
//     --border-color-1: #cecece; // #e7e7e7;
//     --border-color-2: #cecece; // #606060;
//     --border_focus-color-1: #62D2A2; // #0074d9;

//     // Button
//     --button-text-color-1: #333333;
//     --button-text-hover-color-1: #555555; // #FFFFFF;
//     --button-text-active-color-1: #333333; //#cecece;
//     --button-text-toggle-color-1: #333333; // #FFFFFF;

//     --button-background_color-1: transparent; // #0056b3;

//     // --button-toggle-background_color-1: #50fa9f;
//     // --button_hover-background_color-1: #49e090;
//     // --button_active-background_color-1: #42c680;

//     --button-toggle-background_color-1: #62D2A2;
//     --button_hover-background_color-1: #9DF3C4;
//     --button_active-background_color-1: #62D2A2; // #1FAB89;

//     // --button-toggle-background_color-1: #0056b3; // Blue
//     // --button_hover-background_color-1: #0074d9;
//     // --button_active-background_color-1: #004080;

//     // Input
//     --input-background_color-1: #eeeeee;
//     --bubble_input-background_color-1: #f0f0f0; // rgba(204, 204, 204, 0.2);

//     // Misc
//     --scrollbar-color: #404040;
//     --scrollbar-track-color: transparent;
//     --page-background_color-1: #F9F9F9;
//     --bubble-background_color-1: #F9F9F9; // #f0f0f0;
//     --overlay-background_color-1: rgba(255, 255, 255, 0.75);
//     --fade_out-background-color-1: linear-gradient(to bottom, rgba(204, 204, 204, 0), rgba(204, 204, 204, 0.7), rgba(204, 204, 204, 1));
//     // --post-details-fade-color-1: linear-gradient(to bottom, rgba(51, 51, 51, 0), rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 1)); /* Adjust the color to match your container's background */
// }  



// THEME FROM SAM ---------------------------------------------------

[data-theme='light'] { // [data-theme='dark'] // :root
    // Font 
    --font-color-1: black; // #333;
    --font-color-2: #555;
    --font-color-3: #777;

    --font_hover-color-1: #66B2FF;
    --font_toggle-color-1: #007BFF;
    --font_active-color-1: #3399FF;

    --font_success-color-1: #007BFF;
    --font_info-color-1: #0056b3;
    --font_warning-color-1: #fc0;
    --font_danger-color-1: #ed6a52;
    --font_placeholder-color-1: #888;
    --ui-background_color-1: #fff;
    --ui_interior-background_color-1: rgba(244,244,244,255);
    --border-color-1: #cecece;
    --border-color-2: #cecece;
    --border_focus-color-1: #007BFF;
    --button-text-color-1: #333;
    --button-text-hover-color-1: #555;
    --button-text-active-color-1: #333;
    --button-text-toggle-color-1: black;
    --button-background_color-1: #0000;

    --button-toggle-background_color-1: #007BFF;
    --button_hover-background_color-1: #66B2FF;
    --button_active-background_color-1: #3399FF;

    --input-background_color-1: #eee;
    --bubble_input-background_color-1: #f0f0f0;
    --scrollbar-color: #404040;
    --scrollbar-track-color: #0000;
    --page-background_color-1: #f9f9f9;
    --bubble-background_color-1: #f9f9f9;
    --overlay-background_color-1: #ffffffbf;
    --fade_out-background-color-1: linear-gradient(180deg,#ccc0,#ccccccb3,#ccc);
}

:root {
    --font-color-1: #e0e0e0;
    --font-color-2: #a0a0a0;
    --font-color-3: grey;

    --font_hover-color-1: #66B2FF;
    --font_toggle-color-1: #3399FF; // #007BFF;
    --font_active-color-1: #007BFF; // #3399FF;

    // --font_hover-color-1: #009ACD;
    // --font_toggle-color-1: #00BFFF; // #007BFF;
    // --font_active-color-1: #007BB5; // #3399FF;

    --font_success-color-1: #007BFF;
    --font_info-color-1: #428fdc;
    --font_warning-color-1: #f4db1a;
    --font_danger-color-1: #ec5941;
    --font_placeholder-color-1: #a0a0a0;
    --ui-background_color-1: #333;
    --ui_interior-background_color-1: #2a2a2a;
    --border-color-1: #606060;
    --border-color-2: #404040;
    --border_focus-color-1: #007BFF;
    --button-text-color-1: #e0e0e0;


    // --button-text-hover-color-1: #66B2FF;
    // --button-text-toggle-color-1: #3399FF; // #007BFF;
    // --button-text-active-color-1: #007BFF; // #3399FF;

    --button-text-hover-color-1: #009ACD;
    --button-text-toggle-color-1: #00BFFF;
    --button-text-active-color-1: #007BB5;


    --button-background_color-1: #0000;
    --button-toggle-background_color-1: #404040;
    --button_hover-background_color-1: #505050;
    --button_active-background_color-1: #404040;
    --input-background_color-1: #404040;
    --bubble_input-background_color-1: #3d3d3d;
    --scrollbar-color: #2a2a2a;
    --scrollbar-track-color: #0000;
    --page-background_color-1: radial-gradient(circle,#2b2b2b 0%,#222 50%,#1b1b1b 100%);
    --bubble-background_color-1: #404040;
    --overlay-background_color-1: #000000bf;
    --fade_out-background-color-1: linear-gradient(180deg,#3330,#333333b3,#333);
}   



// AI GENERATED THEME -----------------------------------------------

// [data-theme='light'] {
//     --font-color-1: #333333; // Darker grey for better readability on light background
//     --font-color-2: #555555;
//     --font-color-3: #777777;

//     --font_hover-color-1: #62D2A2; // Vibrant green
//     --font_toggle-color-1: #388e3c;
//     --font_active-color-1: #1FAB89;

//     --font_success-color-1: #37c383;
//     --font_info-color-1: #4992fc;
//     --font_warning-color-1: #f5e51b;
//     --font_danger-color-1: #ed6a52;

//     --font_placeholder-color-1: #888888;

//     // UI
//     --ui-background_color-1: #e6e6e6;
//     --ui_interior-background_color-1: #cecece;

//     // Border
//     --border-color-1: #606060;
//     --border-color-2: #cccccc; // #cccccc;
//     --border_focus-color-1: #62D2A2;

//     // Button
//     --button_hover-background_color-1: #9DF3C4;
//     --button_active-background_color-1: #62D2A2;

//     // Input
//     --input-background_color-1: #eeeeee;
//     --bubble_input-background_color-1: rgba(204, 204, 204, 0.2);

//     // Misc
//     --page-background_color-1: radial-gradient(circle, #c8c8c8 0%, #999999 100%);
//     --bubble-background_color-1: #f0f0f0;
//     --overlay-background_color-1: rgba(255, 255, 255, 0.8);
//     --fade_out-background-color-1: linear-gradient(to bottom, rgba(204, 204, 204, 0), rgba(204, 204, 204, 0.7), rgba(204, 204, 204, 1));
// }

