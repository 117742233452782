.commentsbox-wrapper {

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    color: var(--font-color-1);

    // .loading-icon { top: 100%; }
    
    // Custom scrollbar styles
    &::-webkit-scrollbar { width: 5px; }
    // scrollbar-width: thin; // For Firefox
}

// .comment-form .error-message {
//     margin-top: -10px;
//     margin-bottom: 10px;
// }

.commentsbox-list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0px 0px 0px;

    min-height: 50px;
}